import { cn } from '@shared/cove-ui';
import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu';
import { ComponentProps, forwardRef } from 'react';

const NavigationMenu = ({
  className,
  ...props
}: ComponentProps<(typeof NavigationMenuPrimitive)['Root']>) => (
  <NavigationMenuPrimitive.Root
    {...props}
    className={cn('z-10 flex w-full justify-center', className)}
  />
);

const NavigationMenuSub = forwardRef<
  HTMLDivElement,
  ComponentProps<(typeof NavigationMenuPrimitive)['Sub']>
>((props, forwardedRef) => (
  <NavigationMenuPrimitive.Sub {...props} ref={forwardedRef} />
));

const NavigationMenuIndicator = forwardRef<
  HTMLDivElement,
  Omit<
    ComponentProps<(typeof NavigationMenuPrimitive)['Indicator']>,
    'forceMount'
  >
>(({ className, ...props }, forwardedRef) => (
  <NavigationMenuPrimitive.Indicator
    {...props}
    ref={forwardedRef}
    forceMount
    className={cn(
      'bg-cove-navy-blue z-20 h-[1px] opacity-0 transition-opacity data-[state="visible"]:opacity-100',
      className
    )}
  />
));

const NavigationMenuList = forwardRef<
  HTMLUListElement,
  ComponentProps<(typeof NavigationMenuPrimitive)['List']>
>(({ children, className, ...props }, forwardedRef) => (
  <NavigationMenuPrimitive.List
    className={cn('gap-cove-35 flex', className)}
    {...props}
    ref={forwardedRef}
  >
    {children}
  </NavigationMenuPrimitive.List>
));

const NavigationMenuItem = forwardRef<
  HTMLLIElement,
  ComponentProps<(typeof NavigationMenuPrimitive)['Item']>
>((props, forwardedRef) => (
  <NavigationMenuPrimitive.Item {...props} ref={forwardedRef} />
));

const NavigationMenuTrigger = forwardRef<
  HTMLButtonElement,
  ComponentProps<(typeof NavigationMenuPrimitive)['Trigger']>
>((props, forwardedRef) => (
  <NavigationMenuPrimitive.Trigger {...props} ref={forwardedRef} />
));

const NavigationMenuLink = forwardRef<
  HTMLAnchorElement,
  ComponentProps<(typeof NavigationMenuPrimitive)['Link']>
>(({ ...props }, forwardedRef) => (
  <NavigationMenuPrimitive.Link {...props} ref={forwardedRef} />
));

const NavigationMenuContent = forwardRef<
  HTMLDivElement,
  ComponentProps<(typeof NavigationMenuPrimitive)['Content']>
>(({ children, className, ...props }, forwardedRef) => {
  return (
    <NavigationMenuPrimitive.Content
      {...props}
      className={cn(
        'p-cove-25 absolute left-1/2 top-full w-full -translate-x-1/2 bg-white shadow',
        className
      )}
      ref={forwardedRef}
    >
      {children}
    </NavigationMenuPrimitive.Content>
  );
});

const NavigationMenuViewport = forwardRef<
  HTMLDivElement,
  ComponentProps<(typeof NavigationMenuPrimitive)['Viewport']>
>((props, forwardedRef) => (
  <NavigationMenuPrimitive.Viewport {...props} ref={forwardedRef} />
));

NavigationMenu.displayName = 'NavigationMenu';

NavigationMenu.Sub = NavigationMenuSub;
NavigationMenu.Indicator = NavigationMenuIndicator;
NavigationMenu.List = NavigationMenuList;
NavigationMenu.Item = NavigationMenuItem;
NavigationMenu.Trigger = NavigationMenuTrigger;
NavigationMenu.Content = NavigationMenuContent;
NavigationMenu.Link = NavigationMenuLink;
NavigationMenu.Viewport = NavigationMenuViewport;

export default NavigationMenu;
