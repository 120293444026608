import { useEffect } from 'react';

const HISTORY_KEY = 'history';

const useHistory = (): Array<string> => {
  let history = [];
  if (typeof window !== 'undefined') {
    history = JSON.parse(localStorage.getItem(HISTORY_KEY) || '[]');
  }
  return history;
};

const useTrackHistory = () => {
  const history = useHistory();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const nextHistory = [...history];
      nextHistory.push(window.location.pathname);
      localStorage.setItem(HISTORY_KEY, JSON.stringify(nextHistory));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export { useHistory, useTrackHistory };
