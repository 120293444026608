import { cn } from '@shared/cove-ui';
import { cva } from 'class-variance-authority';
import { ComponentProps, forwardRef } from 'react';
import { Modal } from '../modal';
import { ModalAnimation } from '../modal/modal-constants';
import { DrawerContentProps, DrawerSide } from './drawer-constants';

const Drawer = (props: ComponentProps<typeof Modal>) => <Modal {...props} />;

const DrawerTrigger = ({
  ...props
}: ComponentProps<(typeof Modal)['Trigger']>) => <Modal.Trigger {...props} />;

const sideMap: Record<DrawerSide, ModalAnimation> = {
  top: 'slide-from-top',
  left: 'slide-from-left',
  right: 'slide-from-right',
  bottom: 'slide-from-bottom',
};

const drawerParentVariants = cva(
  'fixed left-1/2 top-0 m-0 max-h-screen w-screen max-w-[100vw] border-0 bg-transparent p-0 shadow-none',
  {
    variants: {
      side: {
        top: '',
        left: 'left-0 w-full max-w-screen-xs',
        right: 'left-[unset] right-0 w-full max-w-screen-xs',
        bottom: 'top-[unset] bottom-0',
      },
    },
  }
);

const drawerContentVariants = cva(
  'px-cove-25 sm:px-cove-50 pt-cove-25 pb-cove-50 w-full bg-white',
  {
    variants: {
      side: {
        top: '',
        left: 'w-full h-screen',
        right: 'w-full h-screen',
        bottom: '',
      },
    },
  }
);

const DrawerContent = forwardRef<HTMLDivElement, DrawerContentProps>(
  (
    { children, className, wrapperClassName, side = 'top', ...props },
    forwardedRef
  ) => (
    <Modal.Content
      {...props}
      ref={forwardedRef}
      animation={sideMap[side]}
      noCloseButton
      className={cn(drawerParentVariants({ side }), wrapperClassName)}
    >
      <div className={cn(drawerContentVariants({ side }), className)}>
        {children}
      </div>
    </Modal.Content>
  )
);

Drawer.displayName = 'Drawer';

Drawer.Trigger = DrawerTrigger;
Drawer.Content = DrawerContent;
Drawer.Close = Modal.Close;

export default Drawer;
