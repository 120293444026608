import { cn } from '@shared/cove-ui';
import { SVGProps, forwardRef } from 'react';
import { IconIds, IconProps, iconSizes } from './icon-constants';
import './icon.css';
import sprite from './sprite.svg';

const Icon = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement> & IconProps>(
  ({ icon, className, width, height, viewBox, ...props }, forwardedRef) => {
    if (!icon) {
      return <></>;
    }

    const {
      width: defaultWidth = 34,
      height: defaultHeight = 34,
      viewBox: defaultViewBox = '0 0 34 34',
      classes = '',
    } = iconSizes[icon as IconIds] || {};

    return (
      <svg
        viewBox={viewBox || defaultViewBox}
        width={width || defaultWidth}
        height={height || defaultHeight}
        className={cn(
          'flex h-auto w-auto items-center justify-center',
          classes,
          className
        )}
        {...props}
        ref={forwardedRef}
      >
        <use xlinkHref={`${sprite}#${icon}`} />
      </svg>
    );
  }
);

Icon.displayName = 'Icon';

export default Icon;
