import * as ScrollArea from '@radix-ui/react-scroll-area';
import * as SelectPrimitive from '@radix-ui/react-select';
import { cn, Icon, SmoothMount } from '@shared/cove-ui';
import { cva } from 'class-variance-authority';
import React, { Children, forwardRef, type PropsWithChildren } from 'react';
import { FocusRing } from 'react-aria';
import { Controller } from 'react-hook-form';
import { OptionType, SelectProps } from './select-constants';

const Select = ({
  name,
  control,
  defaultValue,
  error,
  ...props
}: SelectProps) => {
  if (control) {
    return (
      <>
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            return (
              <BaseSelect
                {...field}
                defaultValue={defaultValue}
                onChange={field.onChange}
                {...props}
              />
            );
          }}
        />
        <SmoothMount show={!!error} height fade showOverflow>
          <p role="alert" className="pt-cove-5 text-sm text-cove-red">
            {error}
          </p>
        </SmoothMount>
      </>
    );
  }

  return (
    <>
      <BaseSelect name={name} defaultValue={defaultValue} {...props} />
      <SmoothMount show={!!error} height fade showOverflow>
        <p role="alert" className="pt-cove-5 text-sm text-cove-red">
          {error}
        </p>
      </SmoothMount>
    </>
  );
};

const selectVariants = cva(
  'border-cove-navy-blue px-cove-5 group bg-transparent relative inline-flex h-full w-full items-center border transition duration-300 focus:outline-none aria-[invalid=true]:text-cove-red aria-[invalid=true]:border-cove-red aria-[invalid=true]:bg-cove-baby-dove',
  {
    variants: {
      align: {
        start: 'justify-start pl-cove-15',
        center: 'justify-center pr-cove-15',
        end: 'justify-end pr-cove-35',
      },
    },
  }
);

const BaseSelect = React.forwardRef<
  HTMLButtonElement,
  Omit<SelectProps, 'control'>
>(
  (
    {
      name,
      children,
      onChange,
      defaultValue,
      placeholder,
      align = 'center',
      rounded = false,
      fullWidth = false,
      invalid = false,
      error,
      containerClassName,
      className,
    },
    ref
  ) => {
    return (
      <SelectPrimitive.Root
        defaultValue={defaultValue}
        {...{
          onValueChange: onChange ? value => onChange?.(value) : undefined,
        }}
      >
        <FocusRing
          within
          isTextInput
          focusRingClass={cn(
            'ring-cove-blue ring-1 ring-offset-1 ring-offset-white',
            (invalid || !!error) && 'ring-cove-red'
          )}
        >
          <div
            className={cn(
              'relative h-[34px] w-full',
              fullWidth ? '' : 'max-w-[290px]',
              containerClassName
            )}
          >
            <SelectPrimitive.Trigger
              ref={ref}
              className={cn(
                rounded && 'rounded-full',
                invalid && 'focus-within:border-cove-red',
                (invalid || !!error) && 'mb-cove-5',
                selectVariants({ align }),
                className
              )}
              aria-label={placeholder || name}
              aria-invalid={invalid || !!error}
            >
              <SelectPrimitive.Value
                className={cn(
                  'relative flex w-full flex-1 items-center justify-center'
                )}
                placeholder={placeholder}
              />
              <SelectPrimitive.Icon className="absolute right-0 translate-x-0 translate-y-0 focus:outline-none">
                <Icon
                  icon="Caret"
                  className="text-cove-blue transition duration-300 focus:outline-none group-data-[state=open]:rotate-180"
                />
              </SelectPrimitive.Icon>
            </SelectPrimitive.Trigger>
          </div>
        </FocusRing>
        <SelectPrimitive.Portal>
          <SelectPrimitive.Content
            position="popper"
            asChild
            sideOffset={rounded ? 5 : -1}
            avoidCollisions={false}
          >
            <div
              className={cn(
                'z-[1500] w-[var(--radix-popper-anchor-width)] overflow-hidden',
                Children.toArray(children).length > 6 ? 'h-[225px]' : 'h-auto',
                rounded && 'rounded-[3px]'
              )}
            >
              <SelectPrimitive.Viewport
                className={cn(
                  'w-full border border-cove-navy-blue bg-white shadow',
                  rounded && 'rounded-[3px]'
                )}
              >
                <ScrollArea.Root
                  className="h-full w-full overflow-hidden"
                  type="always"
                >
                  <ScrollArea.Viewport className="h-full w-full rounded-[inherit]">
                    {children}
                  </ScrollArea.Viewport>
                  <ScrollArea.Scrollbar asChild>
                    <div className="flex w-[12px] touch-none select-none p-[2px]">
                      <ScrollArea.Thumb className="relative w-full flex-[1] rounded-full bg-cove-dove" />
                    </div>
                  </ScrollArea.Scrollbar>
                </ScrollArea.Root>
              </SelectPrimitive.Viewport>
            </div>
          </SelectPrimitive.Content>
        </SelectPrimitive.Portal>
      </SelectPrimitive.Root>
    );
  }
);

BaseSelect.displayName = 'BaseSelect';

interface SelectItemProps extends PropsWithChildren {
  value: OptionType['value'];
  align?: 'start' | 'center' | 'end';
  className?: string;
}

const selectItemVariants = cva(
  'px-cove-5 hover:bg-cove-baby-dove flex h-[34px] w-full cursor-default items-center transition-colors hover:outline-none focus:bg-cove-baby-dove focus:outline-none data-[state=checked]:bg-cove-navy-blue data-[state=checked]:text-white',
  {
    variants: {
      align: {
        start: 'justify-start pl-cove-15',
        center: 'justify-center',
        end: 'justify-end pr-cove-35',
      },
    },
  }
);

const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
  ({ align = 'center', className, children, ...props }, forwardedRef) => {
    return (
      <SelectPrimitive.Item
        ref={forwardedRef}
        className={cn(selectItemVariants({ align }), className)}
        {...props}
      >
        <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
      </SelectPrimitive.Item>
    );
  }
);

SelectItem.displayName = 'SelectItem';

Select.Option = SelectItem;

export default Select;
