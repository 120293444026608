import { Icon } from '@shared/cove-ui';
import { Button } from '@shared/cove-ui';
import { cn } from '@shared/cove-ui';

const FooterSocialLinks = ({ className }: { className?: string }) => {
  return (
    <div className={cn('gap-cove-15 flex', className)}>
      <Button
        variant="unstyled"
        className="hover:text-cove-navy-blue shrink-0"
        color="navy-blue"
        href="https://www.instagram.com/covesmart/"
      >
        <Icon icon="InstagramCircle" />
      </Button>
      <Button
        variant="unstyled"
        className="hover:text-cove-navy-blue shrink-0"
        color="navy-blue"
        href="https://www.facebook.com/covesmart/"
      >
        <Icon icon="FacebookCircle" />
      </Button>
      <Button
        variant="unstyled"
        className="hover:text-cove-navy-blue shrink-0"
        color="navy-blue"
        href="https://www.youtube.com/channel/UC0i0Rkot3hmtOyBXhvo3PEw?view_as=subscriber"
      >
        <Icon icon="YoutubeCircle" />
      </Button>
    </div>
  );
};

export default FooterSocialLinks;
