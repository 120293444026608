import * as Dialog from '@radix-ui/react-dialog';
import { useCart } from '@shared/cart-react';
import {
  MonitoringFeature,
  getPlanFeatures,
} from '@shared/cove-data/monitoring';
import { Button, Icon, SmoothMount, cn } from '@shared/cove-ui';
import { useProducts } from '@shared/react-product';
import { useRouter } from '@shared/react-router';
import { AnimatePresence, motion } from 'motion/react';
import React, { useEffect, useState, type PropsWithChildren } from 'react';
import { Text } from '@shared/react-ui-core';

type BaseVariantProps = {
  /** Monitoring plan the customer has selected */
  activePlan: 'bm' | 'vm';
  /** Callback for basic plan select */
  handleBasicSelect: () => void;
  /** Callback for plus plan select */
  handlePlusSelect: () => void;
  /** Url to navigate to when "Back to system" is clicked */
  prevUrl?: string;
  /** Variant */
  variant?: 'base';
};

type LandingPageVariantProps = {
  /** Monitoring plan the customer has selected */
  activePlan?: 'bm' | 'vm';
  /** Callback for basic plan select */
  handleBasicSelect?: () => void;
  /** Callback for plus plan select */
  handlePlusSelect?: () => void;
  /** Url to navigate to when "Back to system" is clicked */
  prevUrl?: string;
  /** Variant */
  variant?: 'landing-page';
};

type Props = {
  /** Hides "*Cameras not included" label
   * useful for pages with multiple MonitoringFeatures component but only want to show the label on one of them
   * */
  hideCamerasNotIncluded?: boolean;
} & (BaseVariantProps | LandingPageVariantProps);

const basicVariantFeatures = [
  {
    title: 'Alexa and Google Home voice control',
    text: 'Use voice commands to arm, disarm, and check the status of your system.',
    plan: 'Plus',
  },
  {
    title: 'Camera Support',
    text: 'Camera integration is only available through the Cove Plus plan.',
    plan: 'Plus',
  },
  {
    title: '$5/month equipment reward',
    text: 'Upgrade your security system on us with a $5 per month credit toward new equipment. You don’t have to do a thing. And the money rolls over at the end of the month if it isn’t used.',
    plan: 'Plus',
  },
  {
    title: 'Lifetime Equipment Warranty*',
    text: 'Maintaining your system won’t cost you a dime. Ever. If something ever goes wrong, we’ll fix it. (Cameras not included)',
    plan: 'Plus',
  },
  {
    title: 'One Year Equipment Warranty',
    text: 'We don’t think you’ll need it, but if you ever have an issue with your equipment, we will fix it for up to one year.',
    plan: 'Basic',
  },
];

const basicVariantModalFeatures = [
  {
    name: 'Equipment Warranty',
    coveBasic: '1 Year Only',
    covePlus: 'Lifetime',
    title: 'Lifetime equipment warranty*',
    tooltip:
      'Maintaining your system won’t cost you a dime. Ever. If something ever goes wrong, we’ll fix it. (Cameras not included)',
  },
  {
    name: 'Alexa and Google Home voice control',
    coveBasic: false,
    covePlus: true,
    title: 'Alexa and Google Home voice control',
    tooltip:
      'Use voice commands to arm, disarm, and check the status of your system.',
  },
  {
    name: 'Camera Support',
    coveBasic: false,
    covePlus: true,
    title: 'Camera support',
    tooltip: 'Camera integration is only available through the Cove Plus plan.',
  },
  {
    name: '$5/Month Equipment Reward',
    coveBasic: false,
    covePlus: true,
    title: '$5/month equipment reward',
    tooltip:
      'Upgrade your security system on us with a $5 per month credit to use towards new equipment. You don’t have to do a thing. And the money rolls over at the end of the month if it isn’t used.',
  },
  {
    name: '24/7 Live Alarm Monitoring',
    coveBasic: true,
    covePlus: true,
    title: '24/7 live alarm monitoring',
    tooltip:
      'With three US-based monitoring stations, you can rest assured that you will always have access to the authorities when you need it.',
  },
  {
    name: 'Protection From Fires, Smoke, & Flooding',
    coveBasic: true,
    covePlus: true,
    title: 'Protection from fires, smoke, and flooding',
    tooltip:
      'Monitored environmental protection can save you thousands of dollars in damages.',
  },
  {
    name: 'Alarm Verification',
    coveBasic: true,
    covePlus: true,
    title: 'Alarm verification',
    tooltip:
      'Confirm or cancel your alarms through the touchscreen panel, via phone call, or by responding to our automated text message.',
  },
  {
    name: 'Faster Response With InstaText™',
    coveBasic: true,
    covePlus: true,
    title: 'Faster response with InstaText',
    tooltip:
      'Our automated text system will send you a message to confirm or cancel immediately after an alarm is triggered.',
  },
  {
    name: '24/7 Customer Support',
    coveBasic: true,
    covePlus: true,
    title: '24/7 customer support',
    tooltip:
      'Our friendly, knowledgeable customer service team is available around the clock to assist with technical issues and account management.',
  },
  {
    name: 'Dual Connection',
    coveBasic: true,
    covePlus: true,
    title: 'Dual connection',
    tooltip:
      'Stay connected to the monitoring team with wired ethernet connection and 4G cellular backup',
  },
  {
    name: '24-Hour Battery Backup',
    coveBasic: true,
    covePlus: true,
    title: '24-hour battery backup',
    tooltip:
      'In the unlikely event that both ethernet and cellular connection are lost, your system hub will stay connected to the monitoring team for up to 24 hours.',
  },
  {
    name: 'Smartphone Control',
    coveBasic: true,
    covePlus: true,
    title: 'Smartphone control',
    tooltip:
      'Keep tabs on your home from anywhere through the Cove Connect app. Set custom routines, arm and disarm your system, and more!',
  },
];

const MonitoringFeatures: React.FC<PropsWithChildren & Props> = ({
  activePlan = 'vm',
  handleBasicSelect,
  handlePlusSelect,
  prevUrl = '/quiz-results/',
  variant = 'base',
  hideCamerasNotIncluded,
  ...props
}) => {
  const [detailsOpen, setDetailsOpen] = useState({});
  const [selectedPlan, setSelectedPlan] = useState('Plus');
  const [isBasicDisabled, setIsBasicDisabled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const products = useProducts();
  const landingVariant = variant === 'landing-page';
  const { navigate } = useRouter();

  const cart = useCart();

  useEffect(() => {
    if (
      Object.keys(cart?.cartProducts)?.some(product =>
        product.toLocaleLowerCase().includes('camera')
      )
    ) {
      setIsBasicDisabled(true);
    } else {
      setIsBasicDisabled(false);
    }
  }, [cart?.cartProducts]);

  const price = products?.['bm-pn']?.price || 19.99;
  const plusPrice = products?.['vm-pn']?.price || 29.99;

  const basicFeatures = getPlanFeatures({
    plan: 'basic',
    includeAlulaFeatures: true,
  });

  const plusFeatures = getPlanFeatures({
    plan: 'plus',
    includeAlulaFeatures: true,
  });

  const monitoringPlans = [
    {
      title: 'Basic',
      features: basicFeatures,
      active: activePlan === 'bm',
      price: price,
      handleSelect: handleBasicSelect,
    },
    {
      title: 'Plus',
      features: plusFeatures,
      active: activePlan === 'vm',
      popular: true,
      price: plusPrice,
      handleSelect: handlePlusSelect,
    },
  ];

  return (
    <section
      className={cn(
        'relative flex w-full flex-col items-center bg-cove-white p-cove-25 px-cove-10 pt-0',
        landingVariant && 'gap-cove-75'
      )}
      {...props}
    >
      {variant === 'base' && (
        <Dialog.Root open={showModal} onOpenChange={setShowModal}>
          <Dialog.Trigger asChild>
            <button className="hidden items-center bg-transparent sm:flex">
              <p className="mb-0 text-sm underline sm:text-base">
                Learn More about Cove Monitoring Plans
              </p>
              <Icon icon="Question" className="text-cove-dark-grey" />
            </button>
          </Dialog.Trigger>
          <Dialog.Portal forceMount>
            <AnimatePresence>
              {showModal && (
                <Dialog.Overlay forceMount asChild>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2, ease: 'easeInOut' }}
                    className="fixed inset-0 z-[112] flex items-center justify-center overflow-auto bg-cove-dove py-8 backdrop-blur-sm transition-opacity"
                  >
                    <Dialog.Content forceMount asChild>
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2, ease: 'easeInOut' }}
                        className="relative mx-cove-25 my-auto max-w-[1167px] rounded bg-white p-5 pt-[60px]"
                      >
                        <Dialog.Close asChild>
                          <button className="absolute right-2 top-2 bg-transparent">
                            <Icon icon="Cancel" width={24} height={24} />
                          </button>
                        </Dialog.Close>
                        <div className="grid grid-cols-[1fr_0.8fr_0.8fr] rounded border border-cove-dove lg:grid-cols-3">
                          <div className="col-span-1 flex items-center border-b border-r border-cove-dove px-cove-15 py-3 text-base font-medium sm:text-[18px] md:px-cove-25 md:py-7 lg:min-w-[300px] lg:px-8 lg:text-h4 lg:font-semibold">
                            Features
                          </div>
                          <div className="relative col-span-1 flex items-center justify-center border-b border-r border-cove-dove px-0 py-3 text-[18px] font-medium md:px-cove-25 md:py-4 lg:px-8 lg:text-h4 lg:font-semibold">
                            Cove{' '}
                            <span className="ml-cove-5 font-semibold text-cove-navy-blue">
                              +
                            </span>
                            <div className="absolute left-1/2 top-0 flex min-w-cove-100 -translate-x-1/2 -translate-y-1/2 transform items-center justify-center gap-cove-5 rounded-full bg-cove-green px-1 py-1 text-xs font-medium text-white sm:min-w-[120px] sm:px-3 md:text-[12px] lg:text-sm">
                              Most Popular
                            </div>
                          </div>
                          <div className="col-span-1 flex items-center justify-center border-b border-cove-dove px-0 py-3 text-center text-[18px] font-medium md:px-cove-25 md:py-4 lg:px-8 lg:text-h4 lg:font-semibold">
                            Cove Basic
                          </div>
                          {basicVariantModalFeatures.map((feature, i) => {
                            return (
                              <>
                                <div
                                  className={cn(
                                    'relative col-span-1 flex items-center gap-cove-5 border-b border-r border-cove-dove p-cove-10 text-[12px] font-normal leading-[19px] sm:px-cove-15 sm:py-3 md:px-cove-25 md:py-4 md:text-[14px] lg:px-8',
                                    i ===
                                      basicVariantModalFeatures.length - 1 &&
                                      'border-b-0'
                                  )}
                                >
                                  {feature.name}
                                  <ModalToolTip feature={feature} />
                                </div>
                                <div
                                  className={cn(
                                    'col-span-1 flex items-center justify-center border-b border-r border-cove-dove p-cove-10 text-center text-[12px] sm:text-sm md:px-cove-25 md:py-4 md:text-base lg:px-8',
                                    i ===
                                      basicVariantModalFeatures.length - 1 &&
                                      'border-b-0'
                                  )}
                                >
                                  {i === 0 ? (
                                    feature.covePlus
                                  ) : feature.covePlus ? (
                                    <Icon
                                      icon="Check"
                                      className="h-[21px] w-[21px] text-cove-green"
                                    />
                                  ) : (
                                    <Icon
                                      icon="Cancel"
                                      className="h-[21px] w-[21px] text-cove-red"
                                    />
                                  )}
                                </div>
                                <div
                                  className={cn(
                                    'col-span-1 flex items-center justify-center border-b border-cove-dove p-cove-10 text-[12px] sm:text-sm md:px-cove-25 md:py-4 md:text-base lg:px-8',
                                    i ===
                                      basicVariantModalFeatures.length - 1 &&
                                      'border-b-0'
                                  )}
                                >
                                  {i === 0 ? (
                                    feature.coveBasic
                                  ) : feature.coveBasic ? (
                                    <Icon
                                      icon="Check"
                                      className="h-[21px] w-[21px] text-cove-green"
                                    />
                                  ) : (
                                    <Icon
                                      icon="Cancel"
                                      className="h-[21px] w-[21px] text-cove-red"
                                    />
                                  )}
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </motion.div>
                    </Dialog.Content>
                  </motion.div>
                </Dialog.Overlay>
              )}
            </AnimatePresence>
          </Dialog.Portal>
        </Dialog.Root>
      )}
      <div className="flex flex-col w-full items-center">
        <div
          className={cn(
            'mb-[30px] mt-cove-25 flex w-full flex-col-reverse justify-center gap-cove-25',
            variant === 'landing-page' &&
              'items-start gap-cove-25 sm:gap-cove-35 md:gap-cove-50 lg:gap-cove-75',
            variant === 'landing-page' ? 'flex-row' : 'max-md:max-w-[395px] md:flex-row-reverse'
          )}
        >
          {monitoringPlans.map((plan, planIndex) => (
            <div className={`w-full  ${variant === 'base' ? 'max-w-[395px]' : 'max-w-[347px]'}`}>
              <div
                key={`monitoring-plan-${planIndex}`}
                className={cn(
                  variant === 'base' &&
                    'relative flex w-full max-w-[395px] flex-col items-center rounded border border-cove-dark-grey border-opacity-20 hover:cursor-pointer',
                  variant === 'base' &&
                    isBasicDisabled &&
                    plan.title === 'Basic' &&
                    'pointer-events-none opacity-50',
                  landingVariant &&
                    'relative flex h-full w-full max-w-[347px] flex-col items-center rounded border border-transparent bg-cove-white px-cove-15 pb-cove-25 pt-cove-15 shadow sm:px-cove-25 sm:pb-cove-35 sm:pt-cove-25',
                  landingVariant && 'border-cove-navy-blue ',
                  plan.active &&
                    variant === 'landing-page' &&
                    'border-cove-green'
                )}
                {...(variant === 'base' &&
                  !isBasicDisabled && {
                    onClick: () => setSelectedPlan(plan.title),
                  })}
              >
                {plan.popular && (
                  <span
                    className={cn(
                      `absolute top-[-17px] rounded-full bg-cove-green px-cove-25 py-[6px] text-[14px] font-medium text-cove-navy-blue`,
                      landingVariant &&
                        `absolute left-1/2 top-0 flex w-[calc(100%-50px)] -translate-x-1/2 translate-y-[calc(-100%-1px)] justify-center rounded-none bg-cove-green p-cove-5 text-cove-navy-blue`
                    )}
                  >
                    Most Popular
                  </span>
                )}
                {variant === 'landing-page' ? (
                  <>
                    <p className="mb-cove-15 text-center text-base font-medium">
                      Cove {plan.title}
                    </p>

                    {plan.features.map((feature, featureIndex) => {
                      return (
                        <FeatureRow
                          key={`monitoring-plan-${planIndex}-${featureIndex}`}
                          keyName={`monitoring-plan-${planIndex}-${featureIndex}`}
                          feature={feature}
                          detailsOpen={detailsOpen}
                          setDetailsOpen={setDetailsOpen}
                        />
                      );
                    })}
                    {!landingVariant && (
                      <div className="mt-auto flex w-full max-w-[290px] flex-col items-center gap-cove-5 pt-5">
                        <p className="text-center text-sm sm:text-base">
                          {Math.round((plan.price * 100) / 30)} cents/day
                        </p>
                        <Button
                          id={`button-to-choose-${plan.title.toLowerCase()}-plan`}
                          onClick={() => plan?.handleSelect?.()}
                          className="min-w-[unset]"
                        >
                          <span className="mr-cove-10 hidden sm:flex">
                            Choose
                          </span>{' '}
                          {plan.title}
                        </Button>
                        <p className="text-base">
                          No contracts. Cancel anytime.
                        </p>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="mb-cove-5 flex w-full items-center justify-between border-b border-cove-dove px-cove-25 pb-cove-5 pt-cove-25">
                      <label className="flex cursor-pointer items-center">
                        <div
                          className={cn(
                            'relative mb-[3px] mr-cove-10 h-[13px] w-[13px] rounded-full border-cove-dove bg-white',
                            selectedPlan !== plan.title && 'border-2'
                          )}
                        >
                          <input
                            type="radio"
                            name="plan"
                            value={plan.title}
                            defaultChecked={plan.title === 'Plus'}
                            className="sr-only"
                          />
                          <SmoothMount show={selectedPlan === plan.title} fade>
                            <div className="absolute h-full w-full rounded-full bg-cove-blue" />
                          </SmoothMount>
                        </div>
                        <p className="text-center text-lg font-medium">
                          {plan.title === 'Plus' ? 'Cove' : 'Cove Basic'}
                          {plan.title === 'Plus' && (
                            <span className="ml-cove-5 text-cove-blue">+</span>
                          )}
                        </p>
                      </label>
                      {plan.title === 'Plus' ? (
                        <p className="mb-0 text-base font-light">
                          ${Math.round((plan.price * 1) / 30)}.00 / day
                        </p>
                      ) : (
                        <p className="mb-0 text-base font-light">
                          $0.{Math.round((plan.price * 100) / 30)} cents / day
                        </p>
                      )}
                    </div>
                    <div className="mb-5 flex w-full flex-col items-start gap-cove-10">
                      {basicVariantFeatures.map((feature, index) => {
                        if (plan.title === 'Plus' && feature.plan === 'Plus') {
                          return (
                            <div
                              key={`feature-${index}`}
                              className="flex items-center gap-cove-5 pl-[30px] text-sm font-medium sm:font-bold"
                            >
                              <Icon icon="Check" className="text-cove-green" />
                              {feature.title}
                            </div>
                          );
                        }
                        if (
                          plan.title === 'Basic' &&
                          feature.title !== 'Lifetime Equipment Warranty*'
                        ) {
                          return (
                            <div
                              key={`feature-${index}`}
                              className="flex items-center gap-cove-5 pl-[30px] text-sm font-medium sm:font-bold"
                            >
                              {plan.title === feature.plan ? (
                                <Icon
                                  icon="Check"
                                  className="text-cove-green"
                                />
                              ) : (
                                <Icon
                                  icon="Cancel"
                                  color="red"
                                  width={19}
                                  height={19}
                                />
                              )}
                              {feature.title}
                            </div>
                          );
                        }
                      })}
                      <p className="mb-0 w-full text-center text-[12px] font-medium sm:font-bold">
                        * No Contracts, Cancel Anytime
                      </p>
                    </div>
                  </>
                )}
              </div>

              {plan.title === 'Plus' && !hideCamerasNotIncluded && (
                <div
                  className={`mt-4 hidden ${landingVariant ? 'sm:flex' : 'md:flex'}`}
                >
                  <Text variant="body2">*Cameras not included</Text>
                </div>
              )}
            </div>
          ))}
        </div>

        {!hideCamerasNotIncluded && (
          <div
            className={`-mt-4 flex w-full ${landingVariant ? 'sm:hidden' : 'md:hidden'}  ${variant === 'base' ? 'max-w-[395px]' : ''}`}
          >
            <Text variant="body2">*Cameras not included</Text>
          </div>
        )}

        {!landingVariant && (
          <div className="mx-auto mb-cove-10 mt-cove-15 flex w-[calc((347px*2)+25px)] flex-col items-center max-[769px]:w-full">
            <p
              className={cn(
                'mb-cove-15 hidden text-[12px]',
                isBasicDisabled && 'flex'
              )}
            >
              Note: Because you have a camera in your personalized system, you
              need the Cove + Camera support
            </p>
            <Button
              color="blue"
              size="full"
              className="mb-cove-25 w-auto px-cove-15 py-2 text-[20px] md:px-cove-100"
              onClick={() => {
                if (selectedPlan === 'Plus') {
                  handlePlusSelect?.();
                } else {
                  handleBasicSelect?.();
                }
                navigate('/checkout/customer-info');
              }}
            >
              Proceed to checkout →
            </Button>
            <Button
              id="back-to-system-link"
              variant="text"
              color="blue"
              href={prevUrl}
              className="underline"
            >
              <Icon icon="ArrowLeft" /> Back to equipment
            </Button>
          </div>
        )}
      </div>
    </section>
  );
};

export default MonitoringFeatures;

const FeatureRow: React.FC<{
  feature: MonitoringFeature;
  keyName: string;
  detailsOpen: Record<string, boolean>;
  setDetailsOpen: (args: Record<string, boolean>) => void;
}> = ({ keyName, feature, detailsOpen, setDetailsOpen, ...props }) => {
  const plusOnlyFeature =
    feature.plans.every(plan => plan === 'plus') && feature.plans.length === 1;

  return (
    <div
      className={cn(
        'w-full border-t border-cove-dark-grey py-cove-25 text-cove-blue',
        plusOnlyFeature && '[&>button]:font-bold'
      )}
      {...props}
    >
      <div className="absolute left-0 z-[1] w-full -translate-y-cove-15 md:w-[calc(347px-2px)]">
        <SmoothMount show={detailsOpen[keyName]} showOverflow fade>
          <div className="z-[1] rounded border border-cove-dark-grey bg-cove-white px-cove-25 py-cove-50 shadow">
            <Button
              id="close-feature-details"
              variant="text"
              onClick={() =>
                setDetailsOpen({ ...detailsOpen, [keyName]: false })
              }
              className="absolute right-1 top-1 rounded-full p-0"
            >
              <Icon icon="Plus" style={{ rotate: '45deg' }} />
            </Button>
            <b>{feature.title}</b>
            <p>{feature.text}</p>
          </div>
        </SmoothMount>
      </div>
      <Button
        id={feature.title}
        variant="text"
        color="blue"
        className="p-0 text-left font-light"
        onClick={() => {
          const detailsOpenUpdate: Record<string, boolean> = {};
          for (const key in detailsOpen) {
            detailsOpenUpdate[key] = false;
          }
          setDetailsOpen({ ...detailsOpenUpdate, [keyName]: true });
        }}
      >
        {feature.title}
      </Button>
    </div>
  );
};

const ModalToolTip: React.FC<{
  feature: { title: string; tooltip: string };
}> = ({ feature }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger asChild>
        <button
          onClick={() => {
            setIsOpen(true);
          }}
          className="absolute bottom-0 right-0 h-cove-15 min-h-cove-15 w-cove-15 min-w-cove-15 rounded-full border-cove-dove bg-transparent text-cove-blue md:static md:border"
        >
          *
        </button>
      </Dialog.Trigger>
      <Dialog.Portal forceMount>
        <AnimatePresence>
          {isOpen && (
            <Dialog.Overlay className="fixed inset-0 z-[125] flex h-full w-full items-center justify-center">
              <Dialog.Content forceMount>
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className="relative flex max-h-[184px] max-w-[367px] flex-col gap-cove-10 rounded border border-cove-dove bg-cove-white px-cove-25 py-cove-35 shadow"
                >
                  <Dialog.Close asChild>
                    <button className="absolute right-2 top-2 bg-transparent">
                      <Icon icon="Cancel" width={20} height={20} />
                    </button>
                  </Dialog.Close>
                  <Dialog.Title className="mb-0 text-center text-base text-cove-navy-blue">
                    {feature.title}
                  </Dialog.Title>
                  <Dialog.Description className="mb-0 text-center text-[14px] leading-4 text-cove-navy-blue">
                    {feature.tooltip}
                  </Dialog.Description>
                </motion.div>
              </Dialog.Content>
            </Dialog.Overlay>
          )}
        </AnimatePresence>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
