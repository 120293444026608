import { cn, Dots, FocusRing } from '../../..';
import { cva } from 'class-variance-authority';
import { forwardRef, LegacyRef } from 'react';
import { ButtonProps } from './button-constants';

const buttonVariants = cva(
  'flex select-none items-center justify-center rounded-full font-sans font-medium transition duration-200 focus:outline-none bg-transparent  cursor-pointer',
  {
    variants: {
      variant: {
        solid: 'shadow text-white border-2 px-cove-15 ',
        dark: 'shadow text-white border-2 px-cove-15 ',
        outlined:
          'shadow bg-white border-2 px-cove-15 border-cove-blue text-cove-blue hover:text-cove-navy-blue data-[loading=false]:disabled:text-cove-dark-grey data-[loading=false]:disabled:border-cove-dark-grey',
        text: 'rounded-none border-0 h-auto w-auto',
        unstyled:
          'h-auto w-auto max-w-none inline rounded-sm hover:text-inherit',
      },
      color: {
        blue: 'text-cove-blue hover:text-cove-navy-blue data-[loading=false]:disabled:text-cove-dark-grey active:text-cove-navy-blue',
        'navy-blue':
          'text-cove-navy-blue hover:text-cove-blue data-[loading=false]:disabled:text-cove-dark-grey active:text-cove-blue',
      },
      size: {
        large: 'h-cove-50 w-full max-w-button',
        small: 'h-cove-35 w-auto max-w-none',
        full: 'h-cove-50 w-full',
        unstyled: '',
      },
    },
    defaultVariants: {
      variant: 'solid',
      color: 'blue',
      size: 'large',
    },
    compoundVariants: [
      {
        variant: 'text',
        className: 'h-auto w-auto max-w-none',
      },
      {
        variant: 'solid',
        color: 'blue',
        className:
          'hover:border-cove-navy-blue hover:bg-cove-navy-blue hover:text-white active:text-white data-[loading=false]:disabled:bg-cove-dark-grey data-[loading=false]:disabled:text-white data-[loading=false]:disabled:border-cove-dark-grey bg-cove-blue border-cove-blue active:border-cove-navy-blue active:bg-cove-navy-blue text-white',
      },
      {
        variant: 'solid',
        color: 'navy-blue',
        className:
          'hover:border-cove-blue hover:bg-cove-blue data-[loading=false]:disabled:bg-cove-dark-grey data-[loading=false]:disabled:border-cove-dark-grey data-[loading=false]:disabled:text-white bg-cove-navy-blue border-cove-navy-blue active:border-cove-blue active:bg-cove-blue text-white hover:text-white active:text-white',
      },
      {
        variant: 'outlined',
        color: 'blue',
        className:
          'border-cove-blue hover:bg-cove-blue data-[loading=false]:disabled:border-cove-dark-grey bg-white active:bg-cove-blue hover:text-white active:text-white',
      },
      {
        variant: 'outlined',
        color: 'navy-blue',
        className:
          'border-cove-navy-blue hover:bg-cove-navy-blue data-[loading=false]:disabled:border-cove-dark-grey bg-white active:bg-cove-navy-blue hover:text-white active:text-white',
      },
    ],
  }
);

const focusRingVariants = cva('', {
  variants: {
    color: {
      blue: 'active:ring-cove-navy-blue ring-cove-navy-blue hover:ring-cove-navy-blue',
      'navy-blue': 'active:ring-cove-blue ring-cove-blue hover:ring-cove-blue',
    },
  },
});

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  (
    {
      loading = false,
      disabled = false,
      variant = 'solid',
      size = 'large',
      color = 'blue',
      className,
      children,
      onClick,
      ...props
    },
    ref
  ) => {
    if (props.href) {
      return (
        <FocusRing focusRingClass={cn(focusRingVariants({ color }))}>
          {/* @ts-expect-error - Type error because of forwardRef */}
          <a
            {...props}
            className={cn(
              buttonVariants({
                variant,
                size: variant === 'unstyled' ? 'unstyled' : size,
                color,
              }),
              className,
              'group/bttn',
              // This allows the button to use the disabled prop when being used as a link
              disabled && 'pointer-events-none border-none bg-cove-dark-grey'
            )}
            style={{
              WebkitTapHighlightColor: 'transparent',
            }}
            onClick={e => {
              if (loading) return;
              onClick?.(e);
            }}
            data-loading={loading}
            ref={ref as LegacyRef<HTMLAnchorElement>}
          >
            {loading ? (
              <Dots
                className={
                  variant === 'solid'
                    ? 'bg-white'
                    : 'bg-cove-blue group-hover/bttn:bg-white'
                }
              />
            ) : (
              children
            )}
          </a>
        </FocusRing>
      );
    }

    return (
      <FocusRing focusRingClass={cn(focusRingVariants({ color }))}>
        {/* @ts-expect-error - Type error because of forwardRef */}
        <button
          {...props}
          className={cn(
            buttonVariants({
              variant,
              size: variant === 'unstyled' ? 'unstyled' : size,
              color,
            }),
            className,
            'group/bttn'
          )}
          style={{
            WebkitTapHighlightColor: 'transparent',
          }}
          onClick={e => {
            if (loading) return;
            onClick?.(e);
          }}
          data-loading={loading}
          disabled={disabled || loading}
          ref={ref as LegacyRef<HTMLButtonElement>}
        >
          {loading ? (
            <Dots
              className={
                variant === 'solid'
                  ? 'bg-white'
                  : 'bg-cove-blue group-hover/bttn:bg-white'
              }
            />
          ) : (
            children
          )}
        </button>
      </FocusRing>
    );
  }
);

Button.displayName = 'Button';

export default Button;
export { buttonVariants };
