import { Icon } from '@shared/cove-ui';
import { cn } from '@shared/cove-ui';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { cva } from 'class-variance-authority';
import React, { forwardRef } from 'react';
import { FocusRing } from 'react-aria';
import { Controller } from 'react-hook-form';
import { CheckboxIndicatorProps, CheckboxProps } from './checkbox-constants';

const Checkbox = ({ name, control, ...props }: CheckboxProps) => {
  if (control) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <BaseCheckbox
              {...field}
              {...props}
              onCheckedChange={field.onChange}
            />
          );
        }}
      />
    );
  }

  return <BaseCheckbox name={name} {...props} />;
};

export const checkboxVariants = cva('', {
  variants: {
    variant: {
      base: `group w-[12px] h-[12px] min-w-[12px] min-h-[12px] flex justify-center items-center shadow-sm bg-white bordered border-2 border-cove-blue transition-all shrink-0 mr-cove-15 data-[invalid='true']:border-cove-red data-[invalid='true']:text-cove-red data-[disabled]:border-cove-dove outline-none`,
      unstyled: '',
    },
  },
  defaultVariants: {
    variant: 'unstyled',
  },
});

const BaseCheckbox = React.forwardRef<
  HTMLButtonElement,
  Omit<CheckboxProps, 'control'>
>(
  (
    { className, variant = 'base', invalid = false, children, ...props },
    ref
  ) => {
    const isUnstyled = variant === 'unstyled';

    return (
      <FocusRing
        within
        focusRingClass={cn(
          'ring-cove-blue ring-1 ring-offset-1 ring-offset-white',
          invalid && 'ring-cove-red'
        )}
      >
        <CheckboxPrimitive.Root
          className={cn(checkboxVariants({ variant }), className)}
          ref={ref}
          aria-invalid={invalid}
          data-invalid={invalid}
          {...props}
        >
          {!isUnstyled && (
            <CheckboxIndicator forceMount variant={variant}>
              <Icon icon="Cancel" width={24} height={24} />
            </CheckboxIndicator>
          )}
          {children}
        </CheckboxPrimitive.Root>
      </FocusRing>
    );
  }
);

export const checkboxIndicatorVariants = cva('', {
  variants: {
    variant: {
      base: `text-cove-blue data-[disabled]:text-cove-dove opacity-0 transition-all data-[state='checked']:opacity-100 group-data-[invalid='true']:text-cove-red`,
      unstyled: '',
    },
  },
  defaultVariants: {
    variant: 'unstyled',
  },
});

const CheckboxIndicator = forwardRef<HTMLButtonElement, CheckboxIndicatorProps>(
  ({ className, variant = 'unstyled', children, ...props }, forwardedRef) => {
    return (
      <CheckboxPrimitive.Indicator
        ref={forwardedRef}
        className={cn(checkboxIndicatorVariants({ variant }), className)}
        {...props}
      >
        {children}
      </CheckboxPrimitive.Indicator>
    );
  }
);

Checkbox.Indicator = CheckboxIndicator;

export default Checkbox;
