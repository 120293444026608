import { useHistory } from '@website/hooks/useHistory';
import { isMobile } from '@website/libs/utils';
import { useEffect } from 'react';

const KLAYVIO_BACK_BUTTON_POPUP_KEY = 'klayvio_back_button_popup';

const MobileBackButtonPopup = () => {
  const history = useHistory();
  useEffect(() => {
    if (typeof window !== 'undefined' && isMobile()) {
      const hasVisited = history.includes(window.location.pathname);
      if (hasVisited) {
        const lastPopupTime = parseInt(
          window.localStorage.getItem(KLAYVIO_BACK_BUTTON_POPUP_KEY) || '0',
        );

        const diff = Date.now() - lastPopupTime;

        if (diff > 1000 * 60 * 60 * 24) {
          window.localStorage.setItem(
            KLAYVIO_BACK_BUTTON_POPUP_KEY,
            `${Date.now()}`,
          );
          window._klOnsite = window._klOnsite || [];
          window._klOnsite.push(['openForm', 'TfxH9T']);
          return;
        }
      }
    }
  }, [history]);

  return null;
};

export default MobileBackButtonPopup;
