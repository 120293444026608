import { ReactNode } from 'react';
import { DOMKeyframesDefinition } from 'framer-motion';
import * as AccordionPrimitive from '@radix-ui/react-accordion';

export type BaseProps =
  | AccordionPrimitive.AccordionSingleProps
  | AccordionPrimitive.AccordionMultipleProps;

export type AccordionSingleProps = AccordionPrimitive.AccordionSingleProps;

export type AccordionMultipleProps = AccordionPrimitive.AccordionMultipleProps;

export type AccordionProps = Omit<BaseProps, 'type'> & {
  variant?: 'base' | 'compact' | 'unstyled';
  type?: 'single' | 'multiple';
  collapsible?: boolean;
  children: ReactNode;
  openAnimationState?: DOMKeyframesDefinition;
  closedAnimationState?: DOMKeyframesDefinition;
};

export type AccordionItemProps = AccordionPrimitive.AccordionItemProps & {
  variant?: 'base' | 'compact' | 'unstyled';
};

export type AccordionTriggerProps = AccordionPrimitive.AccordionTriggerProps & {
  variant?: 'base' | 'compact' | 'unstyled';
  iconClassName?: string;
  Icon?: React.ReactNode;
};

export type AccordionContentProps = AccordionPrimitive.AccordionContentProps & {
  variant?: 'base' | 'compact' | 'unstyled';
};

export const openSelector = '*[data-state="open"][role="region"]';
export const closeSelector = '*[data-state="closed"][role="region"]';
