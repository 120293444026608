import { Motion, animations } from '@shared/cove-ui';
import { cn } from '@shared/cove-ui';
import * as SwitchPrimitive from '@radix-ui/react-switch';
import { cva } from 'class-variance-authority';
import { AnimationPlaybackControls, useAnimate } from 'framer-motion';
import React, { forwardRef, useLayoutEffect } from 'react';
import { FocusRing } from 'react-aria';
import { Controller } from 'react-hook-form';
import { ToggleProps, ToggleThumbProps } from './toggle-constants';

const Toggle = ({ name, control, onChange, ...props }: ToggleProps) => {
  if (control) {
    return (
      <Motion animation="anticipate">
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            return (
              <BaseToggle
                {...field}
                onCheckedChange={field.onChange}
                {...props}
              />
            );
          }}
        />
      </Motion>
    );
  }

  return (
    <Motion animation="anticipate">
      <BaseToggle name={name} {...props} />
    </Motion>
  );
};

const BaseToggle = React.forwardRef<
  HTMLButtonElement,
  Omit<ToggleProps, 'control'>
>(
  (
    {
      variant = 'base',
      className,
      onCheckedChange,
      invalid = false,
      checked,
      children,
      ...props
    },
    ref
  ) => {
    const isUnstyled = variant === 'unstyled';
    const [scope, animate] = useAnimate();

    useLayoutEffect(() => {
      if (!isUnstyled) {
        const { offsetWidth, dataset } = scope?.current?.offsetParent || {
          offsetWidth: 50,
          dataset: { state: 'unchecked' },
        };
        animate(
          scope?.current,
          { x: dataset.state === 'checked' ? offsetWidth - 24 : 4 },
          { ...(animations.anticipate as AnimationPlaybackControls) }
        );
      }
    }, [scope.current, isUnstyled, checked]);

    return (
      <FocusRing
        within
        focusRingClass={cn(
          'ring-cove-blue ring-1 ring-offset-1 ring-offset-white',
          invalid && 'ring-cove-red'
        )}
      >
        <SwitchPrimitive.Root
          className={cn(toggleVariants({ variant }), className)}
          ref={ref}
          aria-invalid={invalid}
          data-invalid={invalid}
          asChild
          onCheckedChange={on => {
            if (!isUnstyled) {
              const width = scope.current.offsetParent.offsetWidth || 50;
              if (on) {
                animate(
                  scope.current,
                  { x: width - 24 },
                  { ...(animations.anticipate as AnimationPlaybackControls) }
                );
              }
              if (!on) {
                animate(
                  scope.current,
                  { x: 4 },
                  { ...(animations.anticipate as AnimationPlaybackControls) }
                );
              }
            }
            onCheckedChange?.(on);
          }}
          checked={checked}
          {...props}
        >
          <button id="trigger">
            {!isUnstyled && <ToggleThumb ref={scope} variant={variant} />}
            {children}
          </button>
        </SwitchPrimitive.Root>
      </FocusRing>
    );
  }
);

export const toggleVariants = cva('', {
  variants: {
    variant: {
      base: `group w-[50px] h-[28px] min-w-[50px] min-h-[28px] bg-cove-dove transition shrink-0 rounded-[14px] data-[invalid='true']:data-[state='checked']:bg-cove-red data-[invalid='true']:data-[state='unchecked']:bg-cove-red data-[state='checked']:bg-cove-green data-[disabled]:data-[state='checked']:bg-cove-dove data-[disabled]:data-[state='unchecked']:bg-cove-dove outline-none relative`,
      unstyled: '',
    },
  },
  defaultVariants: {
    variant: 'base',
  },
});

const ToggleThumb = forwardRef<HTMLButtonElement, ToggleThumbProps>(
  ({ className, variant = 'unstyled', children, ...props }, forwardedRef) => {
    return (
      <SwitchPrimitive.Thumb
        ref={forwardedRef}
        className={cn(toggleThumbVariants({ variant }), className)}
        {...props}
      >
        {children}
      </SwitchPrimitive.Thumb>
    );
  }
);

export const toggleThumbVariants = cva('', {
  variants: {
    variant: {
      base: 'absolute left-0 top-1 h-5 w-5 rounded-full bg-white shadow',
      unstyled: '',
    },
  },
  defaultVariants: {
    variant: 'unstyled',
  },
});

Toggle.Thumb = ToggleThumb;

export default Toggle;
