import { extendTailwindMerge } from 'tailwind-merge';

const twMerge = extendTailwindMerge({
  extend: {
    theme: {
      colors: [
        'cove-green',
        'cove-forest-green',
        'cove-blue',
        'cove-navy-blue',
        'cove-dove',
        'cove-baby-dove',
        'cove-dark-grey',
        'cove-red',
        'cove-white',
      ],
      spacing: [
        'cove-2',
        'cove-5',
        'cove-10',
        'cove-15',
        'cove-25',
        'cove-35',
        'cove-50',
        'cove-75',
        'cove-87',
        'cove-100',
        'cove-137',
        'cove-150',
        'cove-290',
        'cove-320',
      ],
    },
    classGroups: {
      'max-w': ['button', 'xs', 'sm', 'md', 'lg', 'xl', '2xl', 'xxl'],
    },
  },
});

export { twMerge };
