import { cn } from '@shared/cove-ui';
import { FocusRing as AriaFocusRing } from 'react-aria';
import { FocusRingProps } from './focus-ring-constants';

const FocusRing = ({ children, focusRingClass, ...props }: FocusRingProps) => (
  <AriaFocusRing
    focusRingClass={cn(
      `active:ring-cove-navy-blue ring-cove-blue`,
      `hover:ring-cove-navy-blue ring-1 ring-offset-transparent`,
      focusRingClass
    )}
    {...props}
  >
    {children}
  </AriaFocusRing>
);

export default FocusRing;
