import { SmoothMount } from '@shared/cove-ui';
import { Icon } from '@shared/cove-ui';
import { Button, FocusRing } from '@shared/cove-ui';
import { cn } from '@shared/cove-ui';
import { cva } from 'class-variance-authority';
import { forwardRef, useId, useState } from 'react';
import { Controller } from 'react-hook-form';
import { InputProps } from './input-constants';

const inputVariants = cva('', {
  variants: {
    variant: {
      base: 'border-cove-navy-blue px-cove-15 peer mx-auto block w-full border pt-2 transition focus:outline-none focus-within:border-cove-blue aria-[invalid=true]:text-cove-red aria-[invalid=true]:border-cove-red aria-[invalid=true]:bg-cove-baby-dove',
      unstyled: 'focus:outline-none',
    },
  },
  defaultVariants: {
    variant: 'base',
  },
});

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ name, control, ...props }, ref) => {
    if (control) {
      return (
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            return (
              <BaseInput {...props} {...field} onChange={field.onChange} />
            );
          }}
        />
      );
    }

    return <BaseInput name={name} {...props} ref={ref} />;
  }
);

const BaseInput = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      name,
      placeholder,
      invalid = false,
      error,
      rounded = false,
      secured,
      type = 'text',
      className,
      variant = 'base',
      onClick,
      ...props
    }: InputProps,
    ref
  ) => {
    const [showSecured, setShowSecured] = useState(false);
    const inputId = id ? id : useId();
    const unstyled = variant === 'unstyled';

    if (unstyled) {
      return (
        <FocusRing
          within
          isTextInput={type === 'text'}
          focusRingClass={cn((invalid || !!error) && 'ring-cove-red')}
        >
          <input
            ref={ref}
            id={inputId}
            name={name}
            aria-invalid={invalid || !!error}
            aria-label={placeholder}
            className={cn(
              inputVariants({ variant }),
              ` ${invalid && 'focus-within:border-cove-red'}`,
              rounded && 'rounded-full',
              className
            )}
            placeholder={placeholder}
            type={secured && !showSecured ? 'password' : type}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              onClick?.(e);
            }}
            {...props}
          />
        </FocusRing>
      );
    }

    return (
      <div className="w-full">
        <div className={cn(`relative w-full`)}>
          <FocusRing
            within
            isTextInput={type === 'text'}
            focusRingClass={cn(
              'ring-cove-blue ring-1 ring-offset-1 ring-offset-white',
              (invalid || !!error) && 'ring-cove-red'
            )}
          >
            <input
              ref={ref}
              id={inputId}
              name={name}
              aria-invalid={invalid || !!error}
              aria-label={placeholder}
              className={cn(
                inputVariants({ variant }),
                ` ${invalid && 'focus-within:border-cove-red'}`,
                rounded && 'rounded-full',
                className
              )}
              placeholder=" "
              type={secured && !showSecured ? 'password' : type}
              {...props}
            />
          </FocusRing>
          <label
            htmlFor={inputId}
            className={cn(
              'px-cove-15 pointer-events-none absolute left-[1px] top-[2px] -translate-x-[calc(25%-8.5px)] -translate-y-1/4 scale-50 cursor-text select-none whitespace-nowrap text-base font-medium opacity-100 transition-all duration-300',
              'peer-focus-within:top-[2px] peer-focus-within:-translate-x-[calc(25%-8.5px)] peer-focus-within:-translate-y-1/4 peer-focus-within:scale-50 peer-focus-within:font-medium peer-focus-within:opacity-100',
              'peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:translate-x-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:font-light peer-placeholder-shown:opacity-100',
              'peer-aria-[invalid=true]:text-cove-red'
            )}
          >
            {placeholder}
          </label>
          {secured && (
            <Button
              variant="text"
              size="small"
              className="absolute right-0 top-1/2 -translate-y-1/2"
              onClick={() => setShowSecured(!showSecured)}
            >
              <Icon
                icon={showSecured ? 'Visible' : 'Hide'}
                className="text-cove-navy-blue "
              />
            </Button>
          )}
        </div>
        <SmoothMount show={!!error} height fade showOverflow>
          <p role="alert" className="text-cove-red pt-1 text-sm">
            {error}
          </p>
        </SmoothMount>
      </div>
    );
  }
);

export default Input;
