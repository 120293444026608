import { cn } from '@shared/cove-ui';
import { cva } from 'class-variance-authority';
import { motion, type Variants } from 'framer-motion';
import React from 'react';
import { DotsParams } from './dots-constants';

const dotVariants = cva('transition-colors', {
  variants: {
    status: {
      loading: '',
      error: 'bg-cove-red',
      success: 'bg-cove-green',
    },
  },
});

const Dots: React.FC<DotsParams> = ({
  success,
  error,
  className,
  gap = 5,
  ...props
}) => {
  const variants: Variants = {
    loading: index => ({
      y: ['50%', '-50%', '50%'],
      x: [0, 0, 0],
      transition: {
        duration: 1,
        bounce: 1.2,
        repeat: Infinity,
        delay: 0.25 * index,
        ease: 'easeInOut',
      },
    }),
    success: index => ({
      y: 0,
      x:
        index === 0
          ? `calc(100% + ${gap}px)`
          : index === 2
            ? `calc(-100% - ${gap}px)`
            : '0%',
      transition: { type: 'spring' },
    }),
    error: index => ({
      y: 0,
      x:
        index === 0
          ? `calc(100% + ${gap}px)`
          : index === 2
            ? `calc(-100% - ${gap}px)`
            : '0%',
      transition: { type: 'spring' },
    }),
  };

  return (
    <motion.div
      className="flex"
      style={{ gap }}
      transition={{ type: 'spring' }}
      {...props}
    >
      {Array(3)
        .fill(false)
        .map((_, i) => (
          <motion.span
            key={`dot-${i}`}
            animate={error ? 'error' : success ? 'success' : 'loading'}
            variants={variants}
            custom={i}
            className={cn(
              'bg-cove-navy-blue h-[10px] w-[10px] rounded-full',
              className,
              dotVariants({
                status: error ? 'error' : success ? 'success' : 'loading',
              })
            )}
          ></motion.span>
        ))}
    </motion.div>
  );
};

export default Dots;
