import { Children, ReactElement, ReactNode } from 'react';

/**
 * Extracts React children whose display names match the provided names.
 * @template T - The type of display names.
 * @param {ReactNode} children - The React children to extract from.
 * @param {T[]} displayNames - The display names to match. See https://legacy.reactjs.org/docs/react-component.html#displayname.
 * @returns {{ matchedChildren: Partial<Record<T, ReactNode>>, remainingChildren: ReactElement[] }} An object containing matched children and remaining children.
 */
const extractChildren = <T extends string>(
  children: ReactNode,
  displayNames: T[]
): {
  matchedChildren: Partial<Record<T, ReactNode>>;
  remainingChildren: ReactElement[];
} => {
  // Convert children to an array of ReactElements with type information.
  let remainingChildren = Children.toArray(children) as (ReactElement & {
    type: { displayName: string };
  })[];

  // Initialize an object to store matched children.
  let matchedChildren: Partial<Record<T, ReactNode>> = {};

  // Loop through displayNames to find matching children.
  displayNames.forEach(displayName => {
    // Find the first child with a matching display name.
    let found = remainingChildren.find(
      child => child.type && child.type.displayName === displayName
    );
    if (found) {
      // Add the matched child to matchedChildren object.
      matchedChildren[displayName] = found;
      // Remove the matched child from remainingChildren array.
      remainingChildren = remainingChildren.filter(
        child => child.type && child.type.displayName !== displayName
      );
    }
  });

  // Return an object containing matched children and remaining children.
  return { matchedChildren, remainingChildren };
};

export { extractChildren };
