import { cn } from '@shared/cove-ui';
import { cva } from 'class-variance-authority';
import { motion } from 'framer-motion';
import { forwardRef } from 'react';
import { type BoxProps } from './box-constants';

const boxVariants = cva('mx-auto w-full', {
  variants: {
    bg: {
      transparent: `bg-transparent`,
      white: `bg-cove-white text-cove-navy-blue`,
      'navy-blue': `bg-cove-navy-blue text-cove-white`,
      dove: `bg-cove-dove text-cove-navy-blue`,
      'baby-dove': `bg-cove-baby-dove text-cove-navy-blue`,
    },
    variant: {
      base: '',
      center: `flex justify-center items-center`,
      'product-header': `grid grid-cols-1 grid-rows-[auto_1fr] sm:grid-cols-2 sm:grid-rows-1 md:gap-cove-87 lg:px-cove-87 md:py-cove-75 sm:gap-cove-50 md:px-cove-50 sm:py-cove-50 gap-cove-35 px-cove-25 py-cove-50 items-center`,
    },
    width: {
      full: 'max-w-none',
      lg: 'max-w-lg',
      xl: 'max-w-xl',
      xxl: 'max-w-xxl',
    },
  },
  defaultVariants: {
    bg: 'transparent',
    width: 'xxl',
    variant: 'base',
  },
});

const Box = forwardRef<HTMLDivElement, BoxProps>(
  (
    {
      bg = 'transparent',
      variant = 'base',
      className,
      fadeIn = false,
      width = 'xxl',
      children,
      ...props
    },
    ref
  ) => {
    return (
      <motion.div
        initial={{ opacity: fadeIn ? 0 : 1, y: fadeIn ? 50 : 0 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        className={cn(boxVariants({ width, variant, bg }), className)}
        ref={ref}
        {...props}
      >
        {children}
      </motion.div>
    );
  }
);

Box.displayName = 'Box';

export default Box;
export { boxVariants };
