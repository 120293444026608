import { SmoothMount } from '@shared/cove-ui';
import { Icon } from '@shared/cove-ui';
import { Button, Input } from '@shared/cove-ui';
import { cn } from '@shared/cove-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

const emailErrorMessage = 'Please enter a valid email';

const subscribeSchema = yup.object({
  email: yup.string().email(emailErrorMessage).required(emailErrorMessage),
});

type FormValues = yup.InferType<typeof subscribeSchema>;

const FooterSubscribe = ({ className }: { className?: string }) => {
  const { register, formState, handleSubmit } = useForm<FormValues>({
    resolver: yupResolver<FormValues>(subscribeSchema as any) as any,
  });
  const { errors } = formState;

  const onSubmit: SubmitHandler<FormValues> = data => {
    const { email } = data;
  };

  return (
    <div
      className={cn(
        'gap-cove-15 flex w-full max-w-[235px] flex-col items-end',
        className
      )}
    >
      <b className="mb-cove-5 w-full text-left text-lg font-bold">
        Get Cove Deals
      </b>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <fieldset className="flex w-full items-center">
          <div className="relative w-full">
            <Input
              {...register('email')}
              placeholder="Enter your email address"
              variant="unstyled"
              autoComplete="email"
              invalid={!!errors['email']}
              className="text-cove-navy-blue border-cove-navy-blue placeholder-cove-dark-grey h-5 w-full border-b bg-transparent text-sm"
            />
            <SmoothMount
              show={!!errors['email']}
              className="text-cove-red absolute top-full mt-[2px] text-sm"
              fade
              height={false}
            >
              {errors['email']?.message}
            </SmoothMount>
          </div>
          <Button
            type="submit"
            aria-label="Submit Email"
            variant="unstyled"
            className="w-cove-35 h-cove-35 border-cove-navy-blue text-cove-navy-blue flex shrink-0 items-center justify-center rounded-full border"
          >
            <Icon icon="ArrowLongRight" />
          </Button>
        </fieldset>
      </form>
    </div>
  );
};

export default FooterSubscribe;
