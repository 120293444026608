import * as Sentry from '@sentry/gatsby';

Sentry.init({
  dsn: 'https://02475b5fd3b9408486b33e2d85e0ad92@o282499.ingest.sentry.io/4505585500356608',
  allowUrls: [
    /https?:\/\/([a-zA-Z0-9-]+\.)?(covesmart|vercel|covestage)\.(com|app)/,
  ],
  environment: process.env.NODE_ENV,
  integrations: [
    Sentry.thirdPartyErrorFilterIntegration({
      filterKeys: ['covesmart'],
      behaviour: 'drop-error-if-contains-third-party-frames',
    }),
  ],
  enabled: (() => ['production'].indexOf(process.env.NODE_ENV || '') !== -1)(),
  ignoreErrors: [
    'ValidationError',
    'AbortError',
    'QuotaExceededError',
    'AbortError',
    /SecurityError/,
    /Blocked a frame with origin/,
    /Loading chunk/,
    /gtag is not defined/,
    /lastpass/,
    /gtm/,
    /BROWSERTOOLS/,
    'TypeError: The network connection was lost.',
    'TypeError: cancelled',
    'Error: Request failed with status code 400',
    'TypeError: NetworkError when attempting to fetch resource.',
    'TypeError: The request timed out.',
    'TypeError: Failed to fetch',
    'TypeError: Preflight response is not successful',
    'TypeError: Origin https://www.covesmart.com is not allowed by Access-Control-Allow-Origin.',
    'TypeError: The operation couldn’t be completed. Software caused connection abort',
    'Error: Network Error',
    'Non-Error promise rejection captured',
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
  ],
});
