import FooterSocialLinks from './footer-social-links';

const FooterSocial = () => {
  return (
    <div className="flex w-full flex-col items-end">
      <p className="mb-cove-5 w-full text-right text-base">
        Follow us <u>@covesmart</u>
      </p>
      <FooterSocialLinks className="mt-cove-5" />
    </div>
  );
};

export default FooterSocial;
