import { cn } from '@shared/cove-ui';
import { forwardRef } from 'react';
import { SkeletonProps } from './skeleton-constants';

const Skeleton = forwardRef<HTMLDivElement, SkeletonProps>(
  ({ className, ...props }, forwardedRef) => (
    <div
      {...props}
      ref={forwardedRef}
      className={cn('bg-cove-dark-grey/30 animate-pulse', className)}
    ></div>
  )
);

export default Skeleton;
