export interface IconProps {
  icon: IconIds;
  width?: number;
  height?: number;
  viewBox?: string;
  className?: string;
}

export type IconIds =
  | 'ArrowLeft'
  | 'ArrowLongRight'
  | 'Caret'
  | 'Cancel'
  | 'Chat'
  | 'Check'
  | 'FacebookCircle'
  | 'Hamburger'
  | 'Hide'
  | 'InstagramCircle'
  | 'Logo'
  | 'LogoUnderline'
  | 'Minus'
  | 'Phone'
  | 'Plus'
  | 'Question'
  | 'User'
  | 'Visible'
  | 'YoutubeCircle'
  | 'Info';

export const iconSizes: Record<IconIds, IconDimensions & { classes?: string }> =
  {
    ArrowLeft: {
      width: 19,
      height: 19,
      viewBox: '0 0 19 19',
    },
    ArrowLongRight: {
      width: 25,
      height: 19,
      viewBox: '0 0 25 19',
    },
    Cancel: {
      width: 36,
      height: 36,
      viewBox: '0 0 36 36',
    },
    Caret: {
      width: 34,
      height: 34,
      viewBox: '0 0 34 34',
    },
    Chat: {
      width: 36,
      height: 36,
      viewBox: '0 0 36 36',
      classes: 'chat-mask',
    },
    Check: {
      width: 19,
      height: 19,
      viewBox: '0 0 19 19',
    },
    FacebookCircle: {
      width: 31,
      height: 31,
      viewBox: '0 0 31 31',
    },
    Hamburger: {
      width: 36,
      height: 36,
      viewBox: '0 0 36 36',
    },
    Hide: {
      width: 34,
      height: 34,
      viewBox: '0 0 34 34',
    },
    InstagramCircle: {
      width: 31,
      height: 31,
      viewBox: '0 0 31 31',
    },
    Logo: {
      width: 89,
      height: 22,
      viewBox: '0 0 89 22',
    },
    LogoUnderline: {
      width: 36,
      height: 36,
      viewBox: '0 0 36 36',
    },
    Minus: {
      width: 36,
      height: 36,
      viewBox: '0 0 36 36',
    },
    Phone: {
      width: 36,
      height: 36,
      viewBox: '0 0 36 36',
    },
    Plus: {
      width: 36,
      height: 36,
      viewBox: '0 0 36 36',
    },
    Question: {
      width: 34,
      height: 34,
      viewBox: '0 0 34 34',
    },
    User: {
      width: 36,
      height: 36,
      viewBox: '0 0 36 36',
    },
    Visible: {
      width: 34,
      height: 34,
      viewBox: '0 0 34 34',
    },
    YoutubeCircle: {
      width: 31,
      height: 31,
      viewBox: '0 0 31 31',
    },
    Info: {
      width: 14,
      height: 14,
      viewBox: '0 0 14 14',
    },
  };

export type IconDimensions = {
  width: number;
  height: number;
  viewBox: string;
};
