import { SmoothMount } from '@shared/cove-ui';
import { cn } from '@shared/cove-ui';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { cva } from 'class-variance-authority';
import React, { forwardRef } from 'react';
import { FocusRing } from 'react-aria';
import { Controller } from 'react-hook-form';
import {
  RadioGroupProps,
  RadioIndicatorProps,
  RadioItemProps,
} from './radio-group-constants';

const RadioGroup = ({
  name,
  control,
  defaultValue,
  error,
  ...props
}: RadioGroupProps) => {
  if (control) {
    return (
      <>
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            return (
              <BaseRadioGroup
                {...field}
                defaultValue={defaultValue}
                onValueChange={field.onChange}
                {...props}
              />
            );
          }}
        />
        <SmoothMount show={!!error} height fade showOverflow>
          <p role="alert" className="text-cove-red pt-1 text-sm">
            {error}
          </p>
        </SmoothMount>
      </>
    );
  }

  return (
    <>
      <BaseRadioGroup name={name} defaultValue={defaultValue} {...props} />
      <SmoothMount show={!!error} height fade showOverflow>
        <p role="alert" className="text-cove-red pt-1 text-sm">
          {error}
        </p>
      </SmoothMount>
    </>
  );
};

const BaseRadioGroup = React.forwardRef<
  HTMLDivElement,
  Omit<RadioGroupProps, 'control'>
>(({ defaultValue, className, invalid = false, children, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      defaultValue={defaultValue}
      className={cn('group', className)}
      ref={ref}
      aria-invalid={invalid}
      data-invalid={invalid}
      {...props}
    >
      {children}
    </RadioGroupPrimitive.Root>
  );
});

const RadioItem = forwardRef<HTMLButtonElement, RadioItemProps>(
  ({ variant = 'base', className, children, ...props }, forwardedRef) => {
    const isUnstyled = variant === 'unstyled';

    return (
      <FocusRing
        within
        focusRingClass={cn(
          'ring-cove-blue ring-1 ring-offset-1 ring-offset-white'
        )}
      >
        <RadioGroupPrimitive.Item
          ref={forwardedRef}
          className={cn(
            'flex items-center justify-start bg-white focus:outline-none',
            className
          )}
          {...props}
        >
          {!isUnstyled && <RadioIndicator forceMount variant={variant} />}
          {children}
        </RadioGroupPrimitive.Item>
      </FocusRing>
    );
  }
);

RadioGroup.Option = RadioItem;

export const radioIndicatorVariants = cva('', {
  variants: {
    variant: {
      base: `w-[12px] h-[12px] min-w-[12px] min-h-[12px] shadow-sm bg-white bordered border-2 border-cove-blue data-[state='checked']:bg-cove-blue transition shrink-0 rounded-full mr-cove-15 group-data-[invalid='true']:border-cove-red group-data-[invalid='true']:data-[state='checked']:bg-cove-red group-data-[disabled]:border-cove-dark-grey group-data-[disabled]:data-[state='checked']:bg-cove-dark-grey`,
      unstyled: '',
    },
  },
  defaultVariants: {
    variant: 'unstyled',
  },
});

const RadioIndicator = forwardRef<HTMLButtonElement, RadioIndicatorProps>(
  ({ variant, className, children, ...props }, forwardedRef) => {
    return (
      <RadioGroupPrimitive.Indicator
        ref={forwardedRef}
        className={cn(radioIndicatorVariants({ variant }), className)}
        {...props}
      >
        {children}
      </RadioGroupPrimitive.Indicator>
    );
  }
);

RadioGroup.Indicator = RadioIndicator;

export default RadioGroup;
