import { MotionConfig, Transition } from 'framer-motion';
import type { FC, PropsWithChildren } from 'react';
import { AnimationsKeys, animations } from './motion-constants';

const Motion: FC<
  PropsWithChildren & {
    animation?: AnimationsKeys;
    transition?: Transition;
  }
> = ({ animation = 'spring', transition, children }) => {
  const animationVariant = animations[animation];

  return (
    <MotionConfig
      reducedMotion="user"
      transition={{ ...animationVariant, ...transition }}
    >
      {children}
    </MotionConfig>
  );
};

export default Motion;
