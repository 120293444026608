import * as Dialog from '@radix-ui/react-dialog';
import { AnimationProps } from 'framer-motion';
import { ReactNode, MouseEvent } from 'react';

enum ModalAnimations {
  'default' = 'default',
  'slide-from-top' = 'slide-from-top',
  'slide-from-left' = 'slide-from-left',
  'slide-from-right' = 'slide-from-right',
  'slide-from-bottom' = 'slide-from-bottom',
}

export type ModalAnimation = keyof typeof ModalAnimations;

export interface ModalProps extends React.ComponentProps<typeof Dialog.Root> {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  children?: ReactNode;
}

export interface ModalChildrenProps
  extends React.ComponentProps<typeof Dialog.Content> {
  children: ReactNode;
  className?: string;
  overlayClasses?: string;
  CloseButton?: React.FC<{
    onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  }>;
  noCloseButton?: boolean;
  animation?: ModalAnimation;
}

export const modalAnimations: Record<
  ModalAnimation,
  {
    initial: AnimationProps['initial'];
    animate: AnimationProps['animate'];
    exit: AnimationProps['exit'];
  }
> = {
  default: {
    initial: {
      opacity: 0,
      transform: 'translate(-50%, -50%) scale(.75)',
    },
    animate: {
      opacity: 1,
      transform: 'translate(-50%, -50%) scale(1)',
    },
    exit: {
      opacity: 0,
      transform: 'translate(-50%, -50%) scale(.75)',
    },
  },
  'slide-from-top': {
    initial: {
      transform: 'translate(-50%, -100%)',
    },
    animate: {
      transform: 'translate(-50%, 0%)',
    },
    exit: {
      transform: 'translate(-50%, -100%)',
    },
  },
  'slide-from-left': {
    initial: {
      transform: 'translate(-100%, 0%)',
    },
    animate: {
      transform: 'translate(0%, 0%)',
    },
    exit: {
      transform: 'translate(-100%, 0%)',
    },
  },
  'slide-from-right': {
    initial: {
      transform: 'translate(100%, 0%)',
    },
    animate: {
      transform: 'translate(0%, 0%)',
    },
    exit: {
      transform: 'translate(100%, 0%)',
    },
  },
  'slide-from-bottom': {
    initial: {
      transform: 'translate(-50%, 100%)',
    },
    animate: {
      transform: 'translate(-50%, 0%)',
    },
    exit: {
      transform: 'translate(-50%, 100%)',
    },
  },
};
