import * as Sentry from '@sentry/browser';
import {
  calculateTotals as calculateTotalsFromCart,
  CartCustomer,
  CartProduct,
  Discount,
  ProductRecord,
  SKU,
} from '@shared/cart-js';
import { localStore } from '@shared/storage-js';
import { roundTo } from '@shared/utils-js';
import Axios from 'axios';
import { isEqual, update } from 'lodash';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import keyBy from 'lodash/keyBy';
import { v4 as uuid } from 'uuid';
import {
  calculateCartDiscountAmounts,
  DiscountBreakdown,
  generateDiscountBadge,
  missingInitialProducts,
} from './functions';
import { DISCONTINUED_PRODUCTS_MAP, migrateCartData } from './helpers';
import { DatoData, SavedCart } from './schemas';
import {
  AppliedTotals,
  CartPayload,
  CartTotalMap,
} from '@shared/cart-js/src/schemas';

const PKG_PRODUCTS = [
  'CPA-PANEL-345-1',
  'CPA-HUB-345-1',
  'window-sticker-sm',
  'CP-YS-23i-1',
];

type CoveCart = {
  apiV4Url?: string;
  loadRemote?: boolean;
  cartId?: string;
  salesRepId?: string | null;
  referralCode?: string;
  debug?: boolean;
  cart?: any;
  updateCallback?: (cart: any) => void;
  datoData?: any;
  saveCart?: (
    cartId: string,
    body: any,
    options: { apiV4Url?: string }
  ) => Promise<{ data: SavedCart }>;
  shippingMethod?: string;
  prefixLocalStorageKey?: string;
  efTransactionId?: string;
};

const coveCart = async ({
  apiV4Url,
  loadRemote,
  cartId,
  salesRepId,
  referralCode,
  debug = false,
  cart,
  updateCallback,
  datoData,
  saveCart = defaultSaveCart,
  shippingMethod = 'FREE-Regular',
  prefixLocalStorageKey,
  efTransactionId,
}: CoveCart) => {
  let loading = true;
  let lastSaveDuration: number = 0;
  let newCartId;
  let cartItemIds: Array<string> | undefined;
  let cartProducts: Record<SKU, CartProduct> | undefined;
  let customer: CartCustomer = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    postcode: '',
  };
  let products: Record<SKU, ProductRecord> | undefined;
  let subscriptionIds;
  let payLaterSubscriptionIds;
  let valueSubscriptionIds;
  let subscriptions;
  let discounts: DatoData['discounts'];
  let discountBreakdown: DiscountBreakdown | undefined;
  let payLater: DatoData['payLater'];
  let appliedDiscounts;
  let monitoringPlan: CartProduct | null = null;
  let equipmentTaxRate;
  let monitoringTaxRate;
  let taxCalculated;
  let taxPostcode;
  let isPayLater;
  let isCartSaved;
  let isQuizSkipped;
  let isValue;
  let coupons;
  let couponCodes;
  let couponCodeData;
  /**
   * @returns {Object<{
   *  thanks: boolean,
   *  benefits: boolean,
   *  type: string,
   * }>}
   */
  let exclusiveType;
  // Deprecated promo fields
  let promoCode;
  let promoCodeData = {};
  let promoCodeDescription: Array<string> = [];
  let promoCodeDiscounts: Array<string> = [];
  let promoCodeExclusive = false;
  let promoCodeValid;
  // End deprecated promo fields
  let hasExclusiveCoupon;
  let hasValidCouponCode;
  let quizData;
  let freeMonths;
  let isReferral;
  let noSensors;
  let totals: AppliedTotals | undefined;
  let persistedCartData: SavedCart | undefined;
  let isPayLaterEligible;
  let blacklistedPostcode;
  let returningUser: boolean | null = false;
  let surveysTaken: Array<string> = [];
  let cameraType: Array<string> = [];
  let applyCoveCreditAmount;
  let affirmId;
  let affirmAmount;
  let affirmExpires;
  let migratedToAlula = false;

  const cartIdLocalStorageKey = `${prefixLocalStorageKey || ''}CART_ID`;
  const cartLocalStorageKey = `${prefixLocalStorageKey || ''}coveCart`;

  ({ products, discounts, payLater } = (await new Promise((res, rej) => {
    try {
      res(datoData);
    } catch (error) {
      rej(error);
    }
  })?.catch?.(err => {
    Sentry.captureException(err);
  })) as DatoData);

  if (!cartId) {
    log('Creating new cart id');
    newCartId = uuid();
  } else if (cartId && loadRemote) {
    log('Loading cart from api');
    // load from api
    const response = await Axios.get(`${apiV4Url}/cart/${cartId}`, {
      headers: {
        'content-type': 'application/json',
      },
    })
      .then(({ data }) => data)
      .catch(err => {
        /* log to loggly */
        Sentry.captureException(err);
        return null;
      });
    persistedCartData = response as SavedCart;
  } else if (cart) {
    log('Loading existing cart');
    persistedCartData = cart as SavedCart;
  } else if (
    typeof window !== 'undefined' &&
    localStore.getItem(cartLocalStorageKey)
  ) {
    log('Loading cart from local storage');
    persistedCartData = JSON.parse(
      localStore.getItem(cartLocalStorageKey)
    ) as SavedCart;
  }

  loading = false;

  const paramCartId = cartId;
  const paramSalesRepId = salesRepId;
  const paramReferralCode = referralCode;
  if (
    persistedCartData &&
    !isUndefined(products) &&
    !isUndefined(persistedCartData)
  ) {
    Object.keys((persistedCartData as SavedCart).cartProducts)?.forEach?.(
      key => {
        if (products) {
          const sku = DISCONTINUED_PRODUCTS_MAP[key] ?? key;
          const product = products[sku];
          if (product) {
            product.qty =
              persistedCartData.cartProducts[sku]?.qty ??
              products?.[key]?.qty ??
              0;
          }
        }
      }
    );

    persistedCartData.products = products;
    persistedCartData.discounts = discounts;
    persistedCartData.payLater = payLater;
    ({
      cartId,
      salesRepId,
      referralCode,
      cartItemIds,
      cartProducts,
      customer,
      products,
      subscriptions,
      discounts,
      discountBreakdown,
      payLater,
      appliedDiscounts,
      monitoringPlan,
      equipmentTaxRate,
      monitoringTaxRate,
      taxCalculated,
      taxPostcode,
      subscriptionIds,
      payLaterSubscriptionIds,
      valueSubscriptionIds,
      isPayLater,
      isCartSaved,
      isQuizSkipped,
      isValue,
      coupons,
      couponCodes,
      couponCodeData,
      exclusiveType,
      hasExclusiveCoupon,
      hasValidCouponCode,
      promoCode,
      promoCodeData,
      promoCodeDescription,
      promoCodeDiscounts,
      promoCodeExclusive,
      promoCodeValid,
      quizData,
      freeMonths,
      isReferral,
      noSensors,
      totals,
      isPayLaterEligible,
      blacklistedPostcode,
      returningUser,
      surveysTaken,
      cameraType,
      affirmId,
      affirmAmount,
      affirmExpires,
      migratedToAlula = false,
      applyCoveCreditAmount,
    } = persistedCartData as SavedCart);
  }

  let AUTO_SAVE_QUEUE_FN: ReturnType<typeof setTimeout> | null = null;

  const Cart = {
    loading: loading,
    lastSaveDuration: lastSaveDuration,
    cartId: paramCartId || cartId || newCartId,
    salesRepId: paramSalesRepId || salesRepId,
    referralCode: paramReferralCode || referralCode,
    /**
     * @type {Array<string>}
     */
    cartItemIds: cartItemIds || [],
    /**
     * @type {Record<string, import('@shared/cart-js/src/schemas').CartProduct>=}
     */
    cartProducts: cartProducts || {},
    /**
     * @type {Record<string, import('@shared/cart-js/src/schemas').CartCustomer>=}
     */
    customer: customer || {},
    /**
     * @type {Record<string, import('@shared/cart-js/src/schemas').ProductRecord & {qty?: number}>=}
     */
    products: products || {},
    subscriptions: subscriptions || {},
    /**
     * Array of discount records.
     * @type {Array<import('@shared/cart-js/src/schemas').Discount>}
     */
    discounts: discounts || [],
    discountBreakdown: discountBreakdown || [],
    payLater: payLater || {},
    /**
     * Array of discount records.
     * @type {Array<string>}
     */
    appliedDiscounts: appliedDiscounts || [],
    monitoringPlan: monitoringPlan,
    equipmentTaxRate: equipmentTaxRate || 0,
    monitoringTaxRate: monitoringTaxRate || 0,
    taxCalculated: taxCalculated || false,
    taxPostcode: taxPostcode || '',
    subscriptionIds: ['vm-pn', 'vm-pl', 'bm-pl', 'bm-pn'],
    payLaterSubscriptionIds: ['vm-pl', 'bm-pl'],
    valueSubscriptionIds: ['vm-pl', 'vm-pn'],
    isPayLater: isPayLater || false,
    isCartSaved: isCartSaved || false,
    isQuizSkipped: isQuizSkipped || false,
    isValue: isValue || false,
    /**
     * @type {Array<import('@shared/cart-js/src/schemas').DatoCMSCoupon>=}
     */
    coupons: coupons || [],
    /**
     * @type {Array<string>}
     */
    couponCodes: couponCodes || [],
    /**
     * @type {Record<string, import('@shared/cart-js/src/schemas').DatoCMSCoupon>=}
     */
    couponCodeData: couponCodeData || {},
    exclusiveType: exclusiveType || {
      thanks: false,
      benefits: false,
      type: '',
    },
    hasExclusiveCoupon: hasExclusiveCoupon || false,
    hasValidCouponCode: hasValidCouponCode || false,
    promoCode: promoCode || null,
    promoCodeData: promoCodeData,
    /**
     * @type {Array<string>}
     */
    promoCodeDescription: promoCodeDescription || [],
    promoCodeDiscounts: promoCodeDiscounts || [],
    promoCodeValid: promoCodeValid || null,
    promoCodeExclusive: promoCodeExclusive || false,
    quizData: quizData || null,
    freeMonths: freeMonths || null,
    isReferral: isReferral || false,
    noSensors: noSensors || false,
    totals: totals,
    autoSaveQueuedFn: null,
    isPayLaterEligible: isPayLaterEligible || true,
    blacklistedPostcode: blacklistedPostcode || false,
    returningUser: returningUser ?? null,
    surveysTaken: surveysTaken || [],
    cameraType: cameraType || [],
    applyCoveCreditAmount: applyCoveCreditAmount || 0,
    affirmId: affirmId,
    affirmAmount: affirmAmount,
    affirmExpires: affirmExpires,
    migratedToAlula: migratedToAlula,
    shippingMethod,
    efTransactionId: efTransactionId,

    hasAdditionalSensors() {
      const sensorSkus = [
        'CP-DS-345-1',
        'CP-WS-345-1',
        'CP-PIR-345-1',
        '2G-GB-345-1',
        '2GIG-SMKT8-345',
        '2GIG-CO8-345',
        'CP-FS-345-1',
      ];

      return this.cartItemIds.some(sku => sensorSkus.includes(sku));
    },

    async getBannerOffer(email: string) {
      let partnerId = null;
      const result: { data: null | any; error: null | unknown | Error } = {
        data: null,
        error: null,
      };
      try {
        if (typeof window !== 'undefined') {
          partnerId = localStore.getItem('partnerId');
        }
        const emailresponse = await fetch(
          `${apiV4Url}/email/subscription/banner-offer-new/`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({
              email,
              cartId: this.cartId,
              partnerId,
            }),
          }
        );

        if (!emailresponse.ok) {
          const error = await emailresponse.json();
          result.error = error;
        } else {
          this.updateCustomer({ email });
          localStorage.setItem('bannerSubscribed', 'true');
          result.data = { success: true };
        }
      } catch (err) {
        console.error(err);
        result.error = err;
      }
      return result;
    },

    /**
     * @param {number} amount
     */
    setApplyCoveCreditAmount(amount: number, getTotals = true) {
      if (!Number.isNaN(parseFloat(`${amount}`))) {
        this.applyCoveCreditAmount = Math.max(
          0,
          Number(parseFloat(`${amount}`).toFixed(2))
        );
      } else {
        this.applyCoveCreditAmount = 0;
      }
      if (getTotals) {
        const totals = this.calculateTotals(false);
        this.save();
        return totals;
      }
      return true;
    },
    updateSurveysTaken(name: string) {
      const updated = this.surveysTaken;
      !updated.includes(name) && updated.push(name);
      this.surveysTaken = updated;
    },
    async addCameraType(type: string, save = false) {
      const curr = this.cameraType || [];
      !curr.includes(type) && curr.push(type);
      this.cameraType = curr;
      await this.save(save);
    },
    removeCameraType(type: string) {
      const tIndex = this.cameraType.indexOf(type);
      if (tIndex > -1) {
        this.cameraType.splice(tIndex, 1);
      }
    },
    resetCameraType() {
      this.cameraType = [];
    },
    freeCamera() {
      const appliedDiscountsIds = this.appliedDiscounts || [];

      const eufyIndoorCameraSku = 'indoor-camera-eufy';
      const freeIndoorCameraDiscountId = '149385777';

      const freeIndoorCamera = appliedDiscountsIds.includes(
        freeIndoorCameraDiscountId
      );

      if (this.cartItemIds.includes(eufyIndoorCameraSku) && freeIndoorCamera) {
        return eufyIndoorCameraSku;
      }

      return null;
    },
    updateCartItem(
      { sku: productSku, qty }: { sku: SKU; qty: number },
      getTotals = true
    ) {
      if (this.products && this.cartProducts) {
        const sku = DISCONTINUED_PRODUCTS_MAP[productSku] || productSku;
        const product = this.products[sku];
        if (!product) {
          Sentry.captureException(
            new Error(`Cannot update sku (${sku}) not found in products`)
          );
          return false;
        }
        const max = product?.maximumSensorCountOnSite || 10;
        const newQty =
          parseInt(`${qty}`, 10) > max ? max : parseInt(`${qty}`, 10);

        this.products[sku].qty = newQty;
        this.cartProducts[sku] = this.cartProducts[sku] || {
          ...this.products[sku],
        };
        this.cartProducts[sku].qty = newQty;

        if (newQty > 0 && this.cartItemIds.indexOf(sku) === -1) {
          this.cartItemIds.push(sku);
        }
        if (newQty < 1) {
          const i = this.cartItemIds.indexOf(sku);
          if (i > -1) {
            this.cartItemIds.splice(i, 1);
          }
          delete this.products[sku].qty;
          delete this.cartProducts[sku];
        }
        if (getTotals) {
          const totals = this.calculateTotals(false);
          this.save();
          return totals;
        }
      }
      return true;
    },
    updateCartItems(items: Array<{ sku: SKU; qty: number }>, getTotals = true) {
      if (Array.isArray(items)) {
        for (let index = 0; index < items.length; index += 1) {
          this.updateCartItem(items[index], false);
        }
      } else {
        this.updateCartItem(items, false);
      }
      if (getTotals) {
        const totals = this.calculateTotals(false);
        this.save();
        return totals;
      }
      return true;
    },

    getTodaysGrandTotal() {
      if (typeof this.affirmAmount === 'number') {
        return this.getGrandTotal() - this.affirmAmount;
      }

      return this.getGrandTotal();
    },

    getGrandTotal() {
      return this.totals?.totals?.orderTotalWithTax || 0;
    },
    updateMonitoringPlan(sku: SKU, getTotals = true) {
      if (this.products) {
        if (!this.products[sku]) return { error: 'Subscription not found' };
        const newPlanProduct = {
          ...this.products[sku],
          ...this.cartProducts[sku],
          qty: 1,
        };
        // Need to update all `this` values before calling methods which persist.
        // If a method gets called before these values are changed persisted cart
        // gets out of sync.
        this.monitoringPlan = newPlanProduct;
        this.isPayLater = this.payLaterSubscriptionIds.includes(sku);
        this.isValue = this.valueSubscriptionIds.includes(sku);
        const previousSku = this.cartItemIds.filter(
          subSku => this.subscriptionIds.indexOf(subSku) > -1
        )[0];

        if (previousSku && previousSku !== sku) {
          this.updateCartItem({ sku: previousSku, qty: 0 }, false);
        }
        // Persist new values
        this.updateCartItem({ sku, qty: 1 }, false);

        if (getTotals) {
          const totals = this.calculateTotals(false);
          this.save();
          return totals;
        }
      }
      return true;
    },
    async calculateTax(zip: string) {
      if (this.taxPostcode !== zip || !this.taxCalculated) {
        this.taxPostcode = zip;
        this.taxCalculated = true;
        this.save();
      }
    },
    isTaxCalculated() {
      return !!this.taxCalculated;
    },
    checkExclusiveType() {
      const coupons = this.coupons;
      let thanks = false;
      let benefits = false;
      let exclusiveType: string | undefined = '';
      const checkForOneOfExclusiveTypes = (exclusiveTypes: Array<string>) => {
        const coupon = coupons?.find(coupon =>
          exclusiveTypes.includes(coupon?.exclusiveType ?? '-1')
        );

        if (coupon) {
          exclusiveType = coupon.exclusiveType;
        }

        return !!coupon;
      };

      benefits = checkForOneOfExclusiveTypes(['benefits']);
      thanks = checkForOneOfExclusiveTypes([
        'idme',
        'verifypass',
        'influencer',
      ]);

      this.exclusiveType = { thanks, benefits, type: exclusiveType };
    },
    async applyPromo(
      promoCode: string,
      isReferral: boolean = false
    ): Promise<{
      isValid: boolean;
      isActive: boolean;
      couponAlreadyApplied: boolean;
      allCouponDiscountsAlreadyApplied: boolean;
      someCouponDiscountsAlreadyApplied: boolean;
      missingProducts: Array<SKU>;
      couponExclusive: boolean;
      minimumTotal: number;
    }> {
      const uppercaseCode = promoCode.toUpperCase();
      let couponAlreadyApplied = false;
      let allCouponDiscountsAlreadyApplied = false;
      let someCouponDiscountsAlreadyApplied = false;
      let couponExclusive = false;
      let minimumTotal = 0;
      let missingProducts: Array<SKU> = [];

      // Check if cart already has an exclusive coupon applied
      if (this?.coupons?.some?.(coupon => coupon.exclusive)) {
        return {
          isValid: true,
          isActive: true,
          couponAlreadyApplied,
          allCouponDiscountsAlreadyApplied,
          someCouponDiscountsAlreadyApplied,
          missingProducts,
          couponExclusive: true,
          minimumTotal,
        };
      }

      if (
        !!this?.couponCodeData?.[uppercaseCode]?.active &&
        this.coupons?.some(coupon => coupon.couponCode === uppercaseCode)
      ) {
        couponAlreadyApplied = true;
        return {
          isValid: true,
          isActive: true,
          allCouponDiscountsAlreadyApplied,
          someCouponDiscountsAlreadyApplied,
          couponAlreadyApplied,
          couponExclusive,
          minimumTotal,
          missingProducts,
        };
      }

      const prevCouponCodes = this.couponCodes;
      const prevOrderTotal = this?.totals?.totals?.orderTotal || 0;

      this.couponCodes = this.couponCodes?.length
        ? Array.from(new Set([...this.couponCodes, uppercaseCode]))
        : [uppercaseCode];

      this.isReferral = isReferral;
      await this.save();

      const couponDiscounts: Array<{
        couponCode: string;
        discount: string;
      }> = [];

      const appliedCouponDiscounts: Record<string, Array<string>> = {};
      this.coupons?.forEach(async coupon => {
        let skippedDiscounts = 0;
        const exclusive = coupon.exclusive;
        if (
          coupon.couponCode === uppercaseCode &&
          exclusive &&
          (prevCouponCodes || []).length > 0
        ) {
          couponExclusive = true;
          await this.removePromo(uppercaseCode);
        }

        const conditionalProducts: Array<SKU> = [];
        const tempBreakdown = this.calculateCouponBreakdown();
        coupon.discounts.forEach(async discount => {
          const fullDiscount = this.discounts.find(d => d.id === discount.id);
          [...(fullDiscount?.conditionProducts || [])].forEach(product => {
            if (conditionalProducts.includes(product)) {
              return;
            }
            conditionalProducts.push(product);
          });

          const discountedAmount =
            tempBreakdown.find(c => c.couponCodeId === coupon.id)
              ?.totalSavings || 0;

          // Check if the minimumOrderTotalAfterDiscount is more than the cart's total
          const minimumOrderTotalAfterDiscount =
            fullDiscount?.minimumOrderTotalAfterDiscount || 0;
          if (
            fullDiscount &&
            minimumOrderTotalAfterDiscount >
              prevOrderTotal - discountedAmount &&
            minimumOrderTotalAfterDiscount > minimumTotal
          ) {
            minimumTotal = minimumOrderTotalAfterDiscount;
          }

          if (
            !conditionalProducts.every(sku => this.cartItemIds.includes(sku)) &&
            coupon.couponCode === uppercaseCode
          ) {
            missingProducts = conditionalProducts.filter(
              sku => !this.cartItemIds.includes(sku)
            );
            if (missingProducts.length > 0) {
              await this.removePromo(uppercaseCode);
            }
          }

          if (couponDiscounts.some(d => d.discount === discount.id)) {
            const appliedDiscounts =
              appliedCouponDiscounts[coupon.couponCode] || [];

            appliedCouponDiscounts[coupon.couponCode] = [
              ...appliedDiscounts,
              discount.id,
            ];
          } else {
            couponDiscounts.push({
              couponCode: coupon.couponCode,
              discount: discount.id,
            });
          }
        });

        if (skippedDiscounts === (coupon?.discounts || []).length) {
          allCouponDiscountsAlreadyApplied = true;
        }
        if (skippedDiscounts > 0) {
          someCouponDiscountsAlreadyApplied = true;
        }
      });

      if (couponExclusive) {
        this.couponCodes = prevCouponCodes;

        await this.save();
      }

      this.calculateTotals();
      let isActive = this?.couponCodeData?.[uppercaseCode]?.active || false;
      // couponCodeData[uppercaseCode] is undefined if the coupon code is not valid
      let isValid = !!this?.couponCodeData?.[uppercaseCode];

      if (appliedCouponDiscounts[uppercaseCode]) {
        if (
          appliedCouponDiscounts[uppercaseCode].length ===
          this?.couponCodeData?.[uppercaseCode]?.discounts?.length
        ) {
          allCouponDiscountsAlreadyApplied = true;
        } else {
          someCouponDiscountsAlreadyApplied = true;
        }
      }

      // Check if discounts related to the coupon code are already applied
      const currentCoupon = this.couponCodeData?.[uppercaseCode];
      if (currentCoupon) {
        const currentCouponDiscounts = currentCoupon.discounts || [];
        const fullDiscounts = currentCouponDiscounts
          .map(({ id }) => this.discounts.find(it => it.id === id))
          .filter(it => !!it);

        const overrideDiscountsList = fullDiscounts
          .map(discount => discount?.overrideDiscounts || [])
          .flat()
          .map(discount => discount.id);

        if (
          this.appliedDiscounts.some(discount =>
            overrideDiscountsList.includes(discount)
          )
        ) {
          overrideDiscountsList.forEach(async discount => {
            const fullDiscount = this.discounts.find(d => d.id === discount);

            if (fullDiscount) {
              if (fullDiscount.activateCondition === 'couponCode') {
                const couponToRemove = this.coupons?.find(coupon => {
                  return coupon.discounts.some(
                    discount => discount.id === fullDiscount.id
                  );
                });

                if (couponToRemove) {
                  await this.removePromo(couponToRemove.couponCode);
                }
              }
            }
          });
        }
      }

      if (!isValid || allCouponDiscountsAlreadyApplied) {
        await this.removePromo(uppercaseCode);
      }

      this.calculateCouponBreakdown();
      return {
        isValid,
        isActive,
        couponAlreadyApplied,
        allCouponDiscountsAlreadyApplied,
        someCouponDiscountsAlreadyApplied,
        missingProducts,
        couponExclusive,
        minimumTotal,
      };
    },
    async removePromo(code: string) {
      await new Promise(async resolve => {
        if (this.coupons) {
          this.coupons = this.coupons.filter(
            coupon => coupon.couponCode !== code
          );
        }
        if (this.couponCodes) {
          this.couponCodes = this.couponCodes.filter(
            it => it.toUpperCase() !== code.toUpperCase()
          );
        }

        // Deprecated promoCode code
        this.promoCode = null;
        this.promoCodeData = {};
        this.promoCodeDescription = [];
        this.promoCodeExclusive = false;
        this.promoCodeDiscounts = [];
        this.promoCodeValid = null;
        this.isReferral = false;
        // End deprecated promoCode code

        this.calculateCouponBreakdown();
        await this.save();
        this.calculateTotals(false);
        resolve(true);
      });
    },
    /**
     * Uses the discountMap on each cart product to group product discounted amount by discount and group discounts by coupon code.
     *
     */
    calculateCouponBreakdown() {
      let breakdown: DiscountBreakdown = this.discountBreakdown || [];

      if (
        !isEmpty(this.discounts) &&
        !isEmpty(this.cartProducts) &&
        !isEmpty(this.totals)
      ) {
        const { breakdown: discountBreakdown } = calculateCartDiscountAmounts({
          appliedDiscounts: this.appliedDiscounts || [],
          discounts: this.discounts || [],
          coupons: this.coupons || [],
          cartProducts: this.cartProducts || {},
          totals: this.totals || {},
        });
        breakdown = discountBreakdown;
      }

      this.discountBreakdown = breakdown;

      return breakdown;
    },
    updateQuizData(newQuizData: Record<string, any>) {
      this.quizData = {
        ...this.quizData,
        ...newQuizData,
      };
      this.save();
    },
    calculateTotals(autoSave = true) {
      const {
        formattedTotals: totals,
        totals: unformattedTotals,
        cartProducts: calculatedCartProducts,
        ...calculatedTotals
      } = calculateTotalsFromCart(
        {
          ...this,
          cartProducts: Object.values(this.cartProducts || {}),
        } as unknown as CartPayload,
        this.applyCoveCreditAmount
      );

      this.totals = {
        ...calculatedTotals,
        unformattedTotals,
        formattedTotals: totals,
        totals: unformattedTotals,
        cartProducts: keyBy(calculatedCartProducts, 'sku'),
      };

      if (this.totals) {
        this.cartProducts = this.totals.cartProducts;
        this.cartItemIds = this.totals.cartItemIds;
        this.isPayLater = this.totals.isPayLater;
        this.isValue = this.totals.isValue;
        this.monitoringPlan = this.totals.monitoringPlan;
        this.freeMonths = this.totals.freeMonths;
        this.appliedDiscounts = this.totals.appliedDiscounts;
      }

      if (autoSave) {
        this.autoSave();
      }
      if (typeof window !== 'undefined') {
        const updatedCart = {
          ...this,
        };
        if (!isUndefined(updateCallback)) {
          updateCallback({ ...this, ...updatedCart });
        }
      }

      const postcode =
        this.taxPostcode.length === 5
          ? this.taxPostcode
          : this.customer?.postcode || '';

      if (postcode.length === 5 && !this.taxCalculated) {
        this.calculateTax(postcode);
      }

      this.checkExclusiveType();
      this.calculateCouponBreakdown();

      return totals;
    },
    calculateProductDiscounts(sku: SKU) {
      const product = this?.products?.[sku];

      if (!product) {
        return null;
      }

      const appliedDiscounts = (
        this.appliedDiscounts.map(id =>
          this.discounts.find(discount => discount.id === id)
        ) || []
      ).filter(d => !!d);

      const removeFromSalePriceAppliedDiscounts = appliedDiscounts?.filter?.(
        ({ removeFromSalePrice, restrictTo }) =>
          removeFromSalePrice && restrictTo.includes(sku)
      );

      const appliedDiscountsOverRideIds =
        appliedDiscounts
          ?.map?.(({ overrideDiscounts }) =>
            overrideDiscounts?.map?.(({ id }) => id)
          )
          ?.flat() || [];

      const removeFromSalePriceDiscounts = appliedDiscounts?.filter?.(
        ({ id, removeFromSalePrice, restrictTo, activateCondition }) =>
          removeFromSalePrice &&
          restrictTo.includes(sku) &&
          activateCondition === 'product' &&
          !appliedDiscountsOverRideIds.includes(id)
      );

      const otherDiscounts =
        this.cartProducts?.[sku]?.appliedDiscounts?.map(appliedDiscount => {
          const fullDiscount = this.discounts.find(
            d => d.id === appliedDiscount
          );
          return { salePrice: product.salePrice, discount: fullDiscount };
        }) ?? [];

      if (
        !removeFromSalePriceAppliedDiscounts?.length &&
        !removeFromSalePriceDiscounts?.length &&
        !otherDiscounts?.length
      ) {
        return {
          salePrice: roundTo(`${product.price}`),
          displayDiscounts: [],
          productDiscounts: [],
        };
      }

      const filteredDiscounts = Object.values({
        ...keyBy(removeFromSalePriceDiscounts, 'id'),
        ...keyBy(removeFromSalePriceAppliedDiscounts, 'id'),
      });

      const discountArray = filteredDiscounts.map(discount => {
        if (discount.discountType === 'percentage') {
          const salePrice =
            product.price * ((100 - discount.discountAmount) / 100);
          return { salePrice, discount };
        }
        if (discount.discountType === 'fixed') {
          const salePrice = Math.max(
            0,
            product.price - discount.discountAmount
          );
          return { salePrice, discount };
        }
        return { salePrice: 0, discount };
      }, []);

      const bestDiscount = discountArray.reduce((min, current) => {
        if (!min) {
          return current;
        }
        if ((current?.salePrice || 0) < (min?.salePrice || 0)) {
          return current;
        }
        return min;
      }, discountArray[0]);

      const salePrice = bestDiscount?.salePrice ?? product.price;

      return {
        salePrice: roundTo(`${salePrice}`),
        displayDiscounts: [bestDiscount],
        productDiscounts: [...discountArray],
        discountBadges: otherDiscounts
          .map(
            discount =>
              discount.discount &&
              generateDiscountBadge(discount.discount, product)
          )
          .filter(d => !!d),
      };
    },
    updateCustomer(updates: Partial<CartCustomer>) {
      this.customer = { ...this.customer, ...updates };
      this.autoSave();
    },
    saveCart() {
      this.isCartSaved = true;
      const updatedCart = {
        ...this,
      };
      if (!isUndefined(updateCallback)) {
        updateCallback({ ...updatedCart });
      }
      this.save();
    },
    skipQuiz() {
      this.isQuizSkipped = true;
      const updatedCart = {
        ...this,
      };
      if (!isUndefined(updateCallback)) {
        updateCallback({ ...updatedCart });
      }
      this.save();
      this.autoSave();
    },
    setNoSensors(noSensors = false) {
      this.noSensors = noSensors;
      const updatedCart = {
        ...this,
      };
      if (!isUndefined(updateCallback)) {
        updateCallback({ ...updatedCart });
      }
      this.autoSave();
    },
    getCartEquipment() {
      const equipment: Array<ProductRecord> = [];

      this.cartItemIds.forEach(sku => {
        const product = this.products[sku];
        if (!product) return;
        if (product.productType !== 'equipment') return;
        if (PKG_PRODUCTS.includes(sku)) return;
        if (equipment.some(product => product.sku === sku)) return;
        equipment.push(product);
      });

      return equipment.sort((a, b) => a.position - b.position);
    },
    getEquipmentQuantity() {
      const cartEquipment = this.getCartEquipment();
      let quantityProducts = 0;

      if (cartEquipment.length > 0) {
        quantityProducts = cartEquipment.reduce((total, product) => {
          let productQty = total;
          if (product.qty && product.qty > 0) {
            productQty += 1;
          }
          return productQty;
        }, 0);
      }

      return quantityProducts;
    },
    getSensorQuantity() {
      const cartEquipment = this.getCartEquipment();
      let quantityProducts = 0;

      if (cartEquipment.length > 0) {
        quantityProducts = cartEquipment
          .filter(({ sku }) => !PKG_PRODUCTS.includes(sku))
          .reduce((total, product) => {
            let productQty = total;
            if (product.qty && product.qty > 0) {
              productQty += 1;
            }
            return productQty;
          }, 0);
      }

      return quantityProducts;
    },
    async addEverflow({ id }: { id: string }) {
      this.efTransactionId = id;
      await this.save();
      return true;
    },
    async addAffirm(
      { id, amount, expires }: { id: string; amount: number; expires: number },
      getTotals = true
    ) {
      this.affirmId = id;
      this.affirmAmount = amount;
      this.affirmExpires = expires;

      if (getTotals) {
        const totals = this.calculateTotals(false);
        await this.save();
        return totals;
      }
      return true;
    },
    async removeAffirm(getTotals = true) {
      this.affirmId = undefined;
      this.affirmAmount = undefined;
      this.affirmExpires = undefined;

      if (getTotals) {
        const totals = this.calculateTotals(false);
        await this.save();
        return totals;
      }
      return true;
    },
    setMigratedToAlula(migratedToAlula: boolean) {
      this.migratedToAlula = migratedToAlula;
      this.calculateTotals();
    },
    validate({
      checkCart = false,
      defaultCart = false,
      checkCustomer = false,
      checkMonitoring = false,
      excludeCamera = false,
    }) {
      const result = {
        emptyCart: false,
        cartChanged: false,
        invalidCustomerInfo: false,
        missingMonitoring: false,
        noSensors: false,
      };
      if (
        !isUndefined(this.cartItemIds) &&
        !isUndefined(this.products) &&
        !isEmpty(this.products)
      ) {
        const hasPackage = Object.values(this?.cartProducts || {}).some(
          product => {
            return product.productType === 'package';
          }
        );

        // Check if cart empty
        if (checkCart) {
          if (this.getEquipmentQuantity() === 0 && !hasPackage) {
            result.emptyCart = true;
          }

          if (this.getSensorQuantity() === 0) {
            result.noSensors = true;
          }
        }

        if (
          Object.values(this.cartProducts || {}).some(
            product => product.productType === 'package'
          )
        ) {
          let initialItems = [
            { sku: 'CPA-HUB-345-1', qty: 0 },
            { sku: 'CP-YS-23i-1', qty: 0 },
            { sku: 'window-sticker-sm', qty: 0 },
          ];

          this.updateCartItems(initialItems, false);
          result.cartChanged = true;
        }

        /**
         * Default Cart
         */
        if (
          ((checkCart && !result.emptyCart && !result.cartChanged) ||
            defaultCart) &&
          missingInitialProducts({
            products: this.products,
            cartItemIds: this.cartItemIds,
            cartProducts: this.cartProducts,
          })
        ) {
          let initialItems = [
            { sku: 'CPA-HUB-345-1', qty: 1 },
            { sku: 'CPA-PANEL-345-1', qty: 1 },
            { sku: 'CP-YS-23i-1', qty: 1 },
            { sku: 'window-sticker-sm', qty: 3 },
          ];

          console.log('initial items not found, adding...', initialItems);
          this.updateCartItems(initialItems, false);

          if (
            isUndefined(this.monitoringPlan) ||
            isEmpty(this.monitoringPlan)
          ) {
            this.updateMonitoringPlan('vm-pn', false);
          }

          const autoSaveFn = this.getAutoSaveQueuedFn();
          if (autoSaveFn) {
            clearTimeout(autoSaveFn);
          }
          const funcId = setTimeout(() => {
            this.setAutoSaveQueuedFn(null);
            this.calculateTotals();
          }, 4000);
          this.setAutoSaveQueuedFn(funcId);

          result.cartChanged = true;
        }

        if (checkMonitoring) {
          if (
            isUndefined(this.monitoringPlan) ||
            isEmpty(this.monitoringPlan) ||
            !this.monitoringPlan.sku ||
            isUndefined(this.products[this.monitoringPlan.sku]) ||
            isEmpty(this.products[this.monitoringPlan.sku]) ||
            this.products[this.monitoringPlan.sku].qty !== 1 ||
            isUndefined(this.cartProducts[this.monitoringPlan.sku]) ||
            isEmpty(this.cartProducts[this.monitoringPlan.sku]) ||
            this.cartProducts[this.monitoringPlan.sku].qty !== 1
          ) {
            result.missingMonitoring = true;
          }
        }

        // Check Customer is valid
        if (checkCustomer) {
          const customer =
            JSON.parse(localStore.getItem('customer-info')) || {};

          if (
            isUndefined(customer?.isValidating) ||
            isUndefined(customer?.isValid) ||
            customer?.isValidating !== false ||
            customer?.isValid !== true
          ) {
            result.invalidCustomerInfo = true;
          }
        }
      }

      return result;
    },
    autoSave() {
      if (!this.getAutoSaveQueuedFn()) {
        const funcId = setTimeout(() => {
          this.setAutoSaveQueuedFn(null);
          this.save();
        }, 3000);
        this.setAutoSaveQueuedFn(funcId);
      }
    },
    setAutoSaveQueuedFn(funcId: ReturnType<typeof setTimeout> | null) {
      AUTO_SAVE_QUEUE_FN = funcId;
    },
    getAutoSaveQueuedFn() {
      return AUTO_SAVE_QUEUE_FN;
    },
    lastSave: null as Promise<{ data: SavedCart }> | null,
    async save(update = true) {
      const start = performance.now();
      this.loading = true;
      if (debug) {
        log(`Loading: ${this.loading}`);
        console.trace('Saving Cart');
      }
      if (!isUndefined(updateCallback)) {
        updateCallback({ ...this });
      }

      const autoSaveFn = this.getAutoSaveQueuedFn();
      if (autoSaveFn) {
        clearTimeout(autoSaveFn);
      }

      const currentCartProducts = Object.values(
        JSON.parse(JSON.stringify(this?.cartProducts))
      ) as Array<CartProduct>;
      const keyCartProducts = keyBy(
        currentCartProducts?.filter?.(({ qty }) => qty > 0),
        'sku'
      );

      const body = {
        ...this,
        cartProducts: keyCartProducts,
      };

      if (update) {
        localStore.setItem(cartLocalStorageKey, JSON.stringify(this));
      }
      const cartId = body.cartId;

      if (!cartId) {
        return;
      }

      const currentSave = saveCart(cartId, body, { apiV4Url })
        .then(res => {
          this.loading = false;
          if (debug) {
            log(`Loading: ${this.loading}`);
          }
          return res;
        })
        .catch(error => {
          /* log to loggly */
          Sentry.captureException(error);
        }) as Promise<{ data: SavedCart }>;

      this.lastSave = currentSave;
      const result = await currentSave;
      const end = performance.now();
      const time = end - start;
      this.lastSaveDuration = time;
      if (debug) {
        log(`Last Save: ${time}ms`);
      }

      if (!update || this.lastSave !== currentSave) {
        return;
      }

      this.lastSave = null;
      const { data: updatedCart } = result || {};
      if (updatedCart) {
        this.updateCart(updatedCart);
      }
      localStore.setItem(cartLocalStorageKey, JSON.stringify(this));
    },
    updateCartMetaData(
      metadata: Partial<{
        isPayLaterEligible: boolean;
        blacklistedPostcode: boolean;
        returningUser: boolean;
      }> = {}
    ) {
      const {
        isPayLaterEligible = true,
        blacklistedPostcode = false,
        returningUser = false,
      } = metadata;

      if (this.blacklistedPostcode !== blacklistedPostcode) {
        this.blacklistedPostcode = blacklistedPostcode;
      }
      if (this.returningUser !== returningUser) {
        this.returningUser = returningUser;
      }
    },
    updateCart(data: SavedCart) {
      this.updateCartMetaData(data.metadata);

      const basicChecks = [
        'appliedDiscounts',
        'discounts',
        'hasExclusiveCoupon',
        'hasValidCouponCode',
        'salesRepId',
      ] as const;

      basicChecks.forEach(key => {
        if (this && data) {
          if (this[key] && data[key] && !isEqual(this[key], data[key])) {
            //@ts-ignore
            this[key] = data[key];
          }
        }
      });

      this.equipmentTaxRate = data.equipmentTaxRate || 0;
      this.monitoringTaxRate = data.monitoringTaxRate || 0;

      const postcode = data?.taxPostcode
        ? data?.taxPostcode
        : data?.customer?.postcode || '';

      if (postcode.length === 5 && !this.taxCalculated) {
        this.calculateTax(postcode);
      }

      if (
        !isEqual(this.coupons, data.coupons) ||
        !isEqual(this.couponCodes, data.couponCodes) ||
        !isEqual(this.couponCodeData, data.couponCodeData) ||
        data.couponCodes.length !== data.coupons.length
      ) {
        this.couponCodes = data.couponCodes;
        this.couponCodeData = data.couponCodeData;
        this.coupons = Object.values(data.couponCodeData || {}).map(coupon => {
          return coupon;
        });
      }

      if (
        Object.keys(data.couponCodeData || {}).length !==
        (data.couponCodes || []).length
      ) {
        this.couponCodes = Object.keys(data.couponCodeData);
      }

      if (
        !isEmpty(data.appliedDiscounts) &&
        !isEmpty(data.discounts) &&
        !isEmpty(data.coupons) &&
        !isEmpty(this.cartProducts) &&
        !isEmpty(data.totals)
      ) {
        const { breakdown, remove } = calculateCartDiscountAmounts({
          appliedDiscounts: data.appliedDiscounts || [],
          discounts: data.discounts || [],
          coupons: data.coupons || [],
          cartProducts: this.cartProducts || {},
          totals: data.totals || {},
        });

        if (remove.length > 0) {
          remove.forEach(code => this.removePromo(code));
        }

        this.discountBreakdown = breakdown;
      }

      this.calculateTotals(false);
      if (!isUndefined(updateCallback)) {
        updateCallback({ ...this });
      }
    },
    async clear(newCartId?: string) {
      if (typeof window !== 'undefined') {
        this.loading = true;
        await this.save(false);
        if (localStore.removeItem) {
          localStore.removeItem(cartIdLocalStorageKey);
          localStore.removeItem(cartLocalStorageKey);
          localStore.removeItem('quiz-completed');
          localStore.removeItem('lastCheckoutRoute');
        }
        if (updateCallback) {
          updateCallback(
            await coveCart({
              updateCallback,
              apiV4Url,
              ...(newCartId ? { loadRemote: true } : { loadRemote }),
              salesRepId: this.salesRepId,
              referralCode: this.referralCode,
              datoData,
              prefixLocalStorageKey,
              shippingMethod,
              ...(newCartId ? { cartId: newCartId } : {}),
            })
          );
        }
      }
    },
  };

  localStore.setItem(cartLocalStorageKey, JSON.stringify(Cart));
  localStore.setItem(cartIdLocalStorageKey, Cart.cartId);
  migrateCartData(Cart);

  Cart.calculateTotals();
  return Cart;
};

export default coveCart;

async function defaultSaveCart(
  cartId: string,
  body: any,
  { apiV4Url }: { apiV4Url?: string } = {}
) {
  return Axios.post(`${apiV4Url}/cart/${cartId}`, body, {
    headers: {
      'content-type': 'application/json',
    },
  }) as Promise<{ data: SavedCart }>;
}

const logStyle =
  'background: #002334; color: #fff; border-left: 4px solid #3722d3; padding: 2px 8px;';

const log = (message = '') => {
  console.log(`%c${message}`, logStyle);
};

export { defaultSaveCart, log };
