import { animations } from '@shared/cove-ui';
import { Icon } from '@shared/cove-ui';
import { FocusRing } from '@shared/cove-ui';
import { cn } from '@shared/cove-ui';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { cva } from 'class-variance-authority';
import { ValueAnimationTransition, useAnimate } from 'framer-motion';
import {
  Children,
  cloneElement,
  isValidElement,
  useEffect,
  useRef,
} from 'react';
import {
  AccordionContentProps,
  AccordionItemProps,
  AccordionMultipleProps,
  AccordionProps,
  AccordionSingleProps,
  AccordionTriggerProps,
  closeSelector,
  openSelector,
} from './accordion-constants';

const Accordion = ({
  children,
  type = 'single',
  onValueChange,
  variant = 'base',
  collapsible = true,
  openAnimationState = {
    opacity: 1,
    y: 0,
    scale: 1,
  },
  closedAnimationState = {
    opacity: 0,
    y: 5,
    scale: 0.98,
  },
  ...props
}: AccordionProps) => {
  const [scope, animate] = useAnimate();
  const animationRef = useRef<null | ReturnType<typeof animate>>(null);

  const animateOpen = () => {
    if (scope?.current?.querySelector(openSelector)) {
      animationRef.current = animate(
        openSelector,
        {
          height: 'auto',
          ...openAnimationState,
        },
        { ...(animations.spring as ValueAnimationTransition) }
      );
      scope?.current
        ?.querySelectorAll(openSelector)
        .forEach((accordionItem: HTMLElement | null) => {
          if (accordionItem) {
            accordionItem.inert = false;
          }
        });
      animationRef.current;
    }
  };

  const animateClosed = () => {
    if (scope?.current?.querySelector(closeSelector)) {
      animationRef.current = animate(
        closeSelector,
        {
          height: 0,
          ...closedAnimationState,
        },
        { ...(animations.spring as ValueAnimationTransition) }
      );
      scope?.current
        ?.querySelectorAll(closeSelector)
        .forEach((accordionItem: HTMLElement | null) => {
          if (accordionItem) {
            accordionItem.inert = true;
          }
        });
      animationRef.current;
    }
  };

  useEffect(() => {
    animateOpen();
    animateClosed();
  }, []);

  if (type === 'single') {
    return (
      <AccordionPrimitive.Root
        {...(props as AccordionSingleProps)}
        type={'single'}
        collapsible={collapsible}
        asChild
        onValueChange={val => {
          animateOpen();
          animateClosed();
          onValueChange?.(val as string & string[]);
        }}
      >
        <div
          ref={scope}
          data-variant={variant}
          className="group/acc-root w-full"
        >
          {Children.map(children, child => {
            if (isValidElement<AccordionItemProps>(child)) {
              const Element = cloneElement(child, { variant });
              return Element;
            }
            return child;
          })}
        </div>
      </AccordionPrimitive.Root>
    );
  }

  return (
    <AccordionPrimitive.Root
      {...(props as AccordionMultipleProps)}
      type={'multiple'}
      asChild
      onValueChange={val => {
        animateOpen();
        animateClosed();
        onValueChange?.(val as string & string[]);
      }}
    >
      <div ref={scope} data-variant={variant} className="group/acc-root w-full">
        {Children.map(children, child => {
          if (isValidElement<AccordionItemProps>(child)) {
            const Element = cloneElement(child, { variant });
            return Element;
          }
          return child;
        })}
      </div>
    </AccordionPrimitive.Root>
  );
};

const accordionItemVariants = cva('', {
  variants: {
    variant: {
      base: 'border-cove-dove p-[2px] group flex flex-col items-start overflow-hidden border-b last:border-b-0',
      compact:
        'border-b-cove-dove p-[2px] group flex flex-col items-start overflow-hidden border-b last:border-b-0',
      unstyled: 'overflow-hidden',
    },
  },
});

const AccordionItem = ({
  children,
  className,
  variant,
  ...props
}: AccordionItemProps) => {
  return (
    <AccordionPrimitive.Item
      {...props}
      className={cn(accordionItemVariants({ variant }), className)}
    >
      {Children.map(children, child => {
        if (
          isValidElement<AccordionTriggerProps | AccordionContentProps>(child)
        ) {
          const Element = cloneElement(child, { variant });
          return Element;
        }
        return child;
      })}
    </AccordionPrimitive.Item>
  );
};

Accordion.Item = AccordionItem;

const accordionTriggerVariants = cva('', {
  variants: {
    variant: {
      base: 'gap-cove-15 text-cove-navy-blue py-cove-25 group/acc-trig flex w-full select-none items-center justify-between rounded-sm text-left text-lg transition duration-200 focus:outline-none group-first:pt-0 group-last:pb-0',
      compact:
        'flex items-center justify-between w-full group/acc-trig select-none focus:outline-none transition duration-200 py-[2px] group-first:pt-0 group-last:pb-0 rounded-sm',
      unstyled:
        'transition duration-200 select-none focus:outline-none group/acc-trig',
    },
  },
});

const AccordionTrigger = ({
  children,
  className,
  iconClassName,
  variant,
  Icon: CustomIcon,
  ...props
}: AccordionTriggerProps) => {
  const unstyled = variant === 'unstyled';

  return (
    <FocusRing>
      <AccordionPrimitive.Trigger
        {...props}
        className={cn(accordionTriggerVariants({ variant }), className)}
      >
        {children}
        {CustomIcon ? (
          CustomIcon
        ) : unstyled ? null : (
          <div
            aria-hidden
            className={cn(
              'text-cove-blue relative h-9 w-9 shrink-0',
              iconClassName
            )}
          >
            <Icon
              icon="Minus"
              className="absolute inset-0 shrink-0 transition-all group-data-[variant='unstyled']/acc-root:hidden group-data-[state='closed']/acc-trig:rotate-90"
            />
            <Icon
              aria-hidden
              icon="Minus"
              className="absolute inset-0 shrink-0 group-data-[variant='unstyled']/acc-root:hidden"
            />
          </div>
        )}
      </AccordionPrimitive.Trigger>
    </FocusRing>
  );
};

Accordion.Trigger = AccordionTrigger;

const accordionContentVariants = cva('', {
  variants: {
    variant: {
      base: 'px-cove-15 pb-cove-35 group-last:pt-cove-35 w-full text-lg',
      compact: 'px-cove-15 pb-cove-15 pt-0 group-last:pt-cove-15',
      unstyled: 'w-full',
    },
  },
});

const AccordionContent = ({
  children,
  className,
  variant,
  ...props
}: AccordionContentProps) => {
  return (
    <AccordionPrimitive.Content
      {...props}
      className="h-0 w-full opacity-0"
      forceMount
    >
      <div className={cn(accordionContentVariants({ variant }), className)}>
        {children}
      </div>
    </AccordionPrimitive.Content>
  );
};

Accordion.Content = AccordionContent;

Accordion.displayName = Accordion;

export default Accordion;
