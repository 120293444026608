import { Icon } from '@shared/cove-ui';
import { Button } from '@shared/cove-ui';
import { cn, extractChildren } from '@shared/cove-ui';
import { cva } from 'class-variance-authority';
import { forwardRef } from 'react';
import FooterSocial from './footer-social';
import FooterSocialLinks from './footer-social-links';
import FooterSubscribe from './footer-subscribe';
import {
  LayoutChildrenProps,
  LayoutFooterProps,
  LayoutFooterVariant,
  LayoutNavProps,
  LayoutRootProps,
} from './layout-constants';

const Layout = ({ children, className, ...props }: LayoutRootProps) => {
  return (
    <>
      <div
        className={cn('flex min-h-screen w-full flex-col', className)}
        {...props}
      >
        {children}
      </div>
    </>
  );
};

const LayoutNav = forwardRef<HTMLDivElement, LayoutNavProps>(
  ({ children, logoLink = '/', className, ...props }, forwardedRef) => {
    const { matchedChildren, remainingChildren } = extractChildren(children, [
      'LayoutNavLeft',
      'LayoutNavRight',
    ]);

    const layoutNavLeft = matchedChildren.LayoutNavLeft;
    const layoutNavRight = matchedChildren.LayoutNavRight;

    return (
      <nav className={cn('w-full bg-white', className)} {...props}>
        <div
          ref={forwardedRef}
          className="px-cove-25 sm:px-cove-50 max-w-xxl relative mx-auto flex h-[60px] w-full items-center justify-between"
        >
          {layoutNavLeft ? (
            layoutNavLeft
          ) : (
            <Button href={logoLink} variant="unstyled" className="shrink-0">
              <Icon icon="Logo" className="text-cove-green" />
            </Button>
          )}
          {layoutNavRight ? layoutNavRight : remainingChildren}
          <span className="border-cove-dove absolute bottom-0 left-1/2 w-[calc(100%-50px)] max-w-xl -translate-x-1/2 border-t sm:w-[calc(100%-100px)]" />
        </div>
      </nav>
    );
  }
);

const LayoutNavLeft = forwardRef<HTMLDivElement, LayoutChildrenProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn('flex h-full items-center', className)}
        {...props}
      >
        {children}
      </div>
    );
  }
);

LayoutNavLeft.displayName = 'LayoutNavLeft';

const LayoutNavRight = forwardRef<HTMLDivElement, LayoutChildrenProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          'flex h-full items-center *:flex *:h-full *:items-center',
          className
        )}
        {...props}
      >
        {children}
      </div>
    );
  }
);

LayoutNavRight.displayName = 'LayoutNavRight';

const LayoutContent = forwardRef<HTMLDivElement, LayoutChildrenProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <main className={cn('flex-grow', className)} {...props} ref={ref}>
        {children}
      </main>
    );
  }
);

const LayoutFooter = ({
  variant = 'full',
  children,
  ...props
}: LayoutFooterProps) => {
  const { matchedChildren, remainingChildren } = extractChildren(children, [
    'LayoutFooterTop',
    'LayoutFooterRight',
    'LayoutFooterLeft',
  ]);

  const footerTop = matchedChildren.LayoutFooterTop;
  const footerRight = matchedChildren.LayoutFooterRight;
  const footerLeft = matchedChildren.LayoutFooterLeft;

  const borderTop = !!footerTop || !!remainingChildren.length;

  return (
    <footer className="bg-cove-dove w-full" {...props}>
      <div className="px-cove-25 sm:px-cove-50 sm:py-cove-75  py-cove-15 max-w-xxl relative mx-auto flex w-full flex-col items-start justify-center font-light sm:min-h-[278px]">
        {footerTop ? footerTop : remainingChildren}
        <div
          className={cn(
            'gap-cove-25 flex w-full flex-col-reverse items-start justify-between sm:flex-row sm:items-center',
            borderTop
              ? 'sm:border-cove-navy-blue pt-cove-25 sm:mt-cove-25 sm:border-t'
              : ''
          )}
        >
          {footerLeft ? footerLeft : <FooterLeft variant={variant} />}
          {footerRight ? footerRight : <FooterRight variant={variant} />}
        </div>
      </div>
    </footer>
  );
};

const LayoutFooterTop = forwardRef<HTMLDivElement, LayoutChildrenProps>(
  ({ children, ...props }, ref) => {
    return (
      <div ref={ref} {...props}>
        {children}
      </div>
    );
  }
);

LayoutFooterTop.displayName = 'LayoutFooterTop';

const LayoutFooterRight = forwardRef<HTMLDivElement, LayoutChildrenProps>(
  ({ children, ...props }, ref) => {
    return (
      <div ref={ref} {...props}>
        {children}
      </div>
    );
  }
);

LayoutFooterRight.displayName = 'LayoutFooterRight';

const LayoutFooterLeft = forwardRef<HTMLDivElement, LayoutChildrenProps>(
  ({ children, ...props }, ref) => {
    return (
      <div ref={ref} {...props}>
        {children}
      </div>
    );
  }
);

LayoutFooterLeft.displayName = 'LayoutFooterLeft';

const footerLeftVariants = cva('flex h-full text-[12px] leading-4 font-light', {
  variants: {
    variant: {
      full: 'max-w-[266px] lg:max-w-none w-full',
      minimal: 'max-w-[258px] w-full',
    },
  },
  defaultVariants: {
    variant: 'full',
  },
});

const FooterLeft = ({ variant }: { variant: LayoutFooterVariant }) => {
  const full = variant === 'full';

  return (
    <div className="gap-cove-15 flex shrink-0 flex-col-reverse items-start sm:flex-row sm:items-center">
      <Icon icon="LogoUnderline" className="hidden h-9 w-9 shrink-0 sm:block" />
      {full && (
        <div className="gap-cove-15 flex sm:hidden lg:flex">
          <Button
            href="/legal"
            variant="unstyled"
            color="navy-blue"
            className="text-[12px]"
          >
            Legal
          </Button>
          <Button
            href="/privacy-policy"
            variant="unstyled"
            color="navy-blue"
            className="text-[12px]"
          >
            Privacy
          </Button>
        </div>
      )}
      <p
        className={cn(
          footerLeftVariants({ variant: full ? 'full' : 'minimal' })
        )}
      >
        © 2024 Cove Smart, LLC All rights reserved. 14015 Minuteman Drive,
        Draper, UT 84020
      </p>
    </div>
  );
};

const FooterRight = ({ variant }: { variant: LayoutFooterVariant }) => {
  const full = variant === 'full';
  const social = variant === 'social';
  const minimal = variant === 'minimal';
  const subscribe = variant === 'subscribe';

  if (minimal) {
    return null;
  }

  return (
    <>
      {full && <FooterSocialLinks />}
      {social && <FooterSocial />}
      {subscribe && <FooterSubscribe />}
    </>
  );
};

Layout.Nav = LayoutNav;
Layout.NavLeft = LayoutNavLeft;
Layout.NavRight = LayoutNavRight;
Layout.Content = LayoutContent;
Layout.Footer = LayoutFooter;
Layout.FooterTop = LayoutFooterTop;
Layout.FooterLeft = LayoutFooterLeft;
Layout.FooterRight = LayoutFooterRight;
Layout.FooterSubscribe = FooterSubscribe;
Layout.FooterSocialLinks = FooterSocialLinks;

export default Layout;
