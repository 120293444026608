import { Transition } from 'framer-motion';

// Valid transition props can be found here: https://www.framer.com/motion/transition/

export type AnimationsKeys =
  | 'spring'
  | 'anticipate'
  | 'linear'
  | 'instant';

export interface AnimationsProps extends Record<AnimationsKeys, Transition> {}

const animations: AnimationsProps = {
  spring: {
    type: 'spring',
    stiffness: 300,
    damping: 30,
    mass: 1,
  },
  anticipate: {
    ease: 'anticipate',
    duration: 0.3,
  },
  linear: {
    duration: 0.3,
  },
  instant: {
    duration: 0,
  },
};

const animationKeys: (keyof AnimationsProps)[] = Object.keys(
  animations
) as unknown as (keyof AnimationsProps)[];

const defaultTransition = animations.spring;

export { animationKeys, animations, defaultTransition };

